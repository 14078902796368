import useLocalState from "./useLocalState";
import { v4 as uuidv4 } from 'uuid';

export const useDemoPassiveIncomeEntries = (accountId) => {
    const [passiveIncomeEntries, setPassiveIncomeEntries] = useLocalState('passiveIncomeEntries',[]);

    const addPassiveIncomeEntry = async ({title, amountInCents}) => {
        setPassiveIncomeEntries([...passiveIncomeEntries, {
            id: uuidv4(),
            userId: accountId,
            amountInCents: amountInCents,
            loggedAt: new Date(),
            title,
        }])
    }

    const removePassiveIncomeEntry = async (id) => {
        setPassiveIncomeEntries(passiveIncomeEntries.filter(entry => entry.id !== id))
    }

    const passiveIncomeEntriesTotal = passiveIncomeEntries.reduce((acc, entry) => acc + entry.amountInCents, 0);

    return { passiveIncomeEntries, passiveIncomeEntriesTotal, addPassiveIncomeEntry, removePassiveIncomeEntry };
};

