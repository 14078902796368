import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {
    CogIcon,
    WalletIcon,
    HomeIcon,
    RocketLaunchIcon,
} from "@heroicons/react/16/solid";
import {signOut} from "firebase/auth";
import {auth} from "../firebaseInit";
import {DialogBackdrop, Dialog, DialogPanel, TransitionChild, MenuItems, MenuItem, Menu, MenuButton} from "@headlessui/react";
import {useState} from "react";

// Don't change slug, it will break "current" page logic
const navigation = {
    demo: [
        { name: 'Dashboard', slug: "dashboard", href: '/demo', icon: HomeIcon },
        { name: 'Goals', slug: "goals", href: '/demo/goals', icon: RocketLaunchIcon },
        { name: 'Settings', slug: "settings", href: '/demo/settings', icon: CogIcon },
    ],
    app: [
        { name: 'Dashboard', slug: "dashboard", href: '/app', icon: HomeIcon },
        { name: 'Goals', slug: "goals", href: '/app/goals', icon: RocketLaunchIcon },
        { name: 'Settings', slug: "settings", href: '/app/settings', icon: CogIcon }
]}

function getNavigation(mode) {
    if (mode === "demo") {
        return navigation.demo
    }

    return navigation.app
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const Layout = ({children, aside, pageSlug, mode}) => {
    const [sidebarOpen, setSidebarOpen] = useState(false)

    return <>
    <div>
        <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 lg:hidden">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
            >
                Hello
            </DialogBackdrop>

            <div className="fixed inset-0 flex">
                <DialogPanel
                    transition
                    className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
                >
                    <TransitionChild>
                        <div
                            className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                            <button type="button" onClick={() => setSidebarOpen(false)}
                                    className="-m-2.5 p-2.5">
                                <span className="sr-only">Close sidebar</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white"/>
                            </button>
                        </div>
                    </TransitionChild>

                    <div
                        className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                        <div className="flex h-16 shrink-0 items-center">
                            <WalletIcon className="h-8 w-8 text-indigo-600"/>
                            {/*<WalletIcon className="h-8 w-8 text-indigo-600"/>*/}
                            {/*<img*/}
                            {/*    alt="Your Company"*/}
                            {/*    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"*/}
                            {/*    className="h-8 w-auto"*/}
                            {/*/>*/}
                        </div>
                        <nav className="flex flex-1 flex-col">
                            <ul role="list" className="-mx-2 flex-1 space-y-1">
                                {getNavigation(mode).map((item) => (
                                    <li key={item.name}>
                                        <a
                                            href={item.href}
                                            className={classNames(
                                                item.current
                                                    ? 'bg-gray-800 text-white'
                                                    : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                                                'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                                            )}
                                        >
                                            <item.icon aria-hidden="true" className="h-6 w-6 shrink-0"/>
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </DialogPanel>
            </div>
        </Dialog>

        <div
            className="hidden lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block lg:w-20 lg:overflow-y-auto lg:bg-gray-900 lg:pb-4">
            <div className="flex h-16 shrink-0 items-center justify-center">
                <WalletIcon className="h-8 w-8 text-indigo-600"/>

            </div>
            <nav className="mt-8">
                <ul role="list" className="flex flex-col items-center space-y-1">
                    {getNavigation(mode).map((item) => (
                        <li key={item.name}>
                            <a
                                href={item.href}
                                className={classNames(
                                    item.slug == pageSlug ? 'bg-gray-800 text-white' : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                                    'group flex gap-x-3 rounded-md p-3 text-sm font-semibold leading-6',
                                )}
                            >
                                <item.icon aria-hidden="true" className="h-6 w-6 shrink-0"/>
                                <span className="sr-only">{item.name}</span>
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>

        <div className="lg:pl-20">
            <div
                className="sticky top-0 z-10 flex h-10 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                <button type="button" onClick={() => setSidebarOpen(true)}
                        className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon aria-hidden="true" className="h-6 w-6"/>
                </button>

                <div aria-hidden="true" className="h-6 w-px bg-gray-900/10 lg:hidden"/>

                <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                    <div className="relative flex flex-1">
                        <div className="font-semibold self-center">Zefire {mode == "demo" ? "Demo Mode" : ""}</div>
                    </div>
                    <div className="flex items-center gap-x-4 lg:gap-x-6">

                        {/* Separator */}
                        <div aria-hidden="true" className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"/>

                        {/* Profile dropdown */}
                        <Menu as="div" className="relative">
                            <MenuButton className="-m-1.5 flex items-center p-1.5">
                                <span className="sr-only">Open user menu</span>
                                <span className="hidden lg:flex lg:items-center">
                      <span aria-hidden="true" className="ml-4 text-sm font-semibold leading-6 text-gray-900">
                        {mode == "demo" ? "Demo user" : "User Menu"}
                      </span>
                      <ChevronDownIcon aria-hidden="true" className="ml-2 h-5 w-5 text-gray-400"/>
                    </span>
                            </MenuButton>
                            <MenuItems
                                transition
                                className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                                <MenuItem key={"0"}>
                                    <a
                                        href={`/${mode == 'demo' ? 'demo' : 'app'}/terms`}
                                        className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                                    >
                                        Terms & Conditions
                                    </a>
                                </MenuItem>
                                <MenuItem key={"1"}>
                                    <a
                                        href={"#"}
                                        onClick={async () => await signOut(auth)}
                                        className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                                    >
                                        Sign out
                                    </a>
                                </MenuItem>

                            </MenuItems>
                        </Menu>
                    </div>
                </div>
            </div>

            <main className={classNames(aside ? "xl:pl-96" : "")}>
                {children}
            </main>
        </div>

        <aside className={classNames(aside ? "fixed top-0 left-20 h-screen bg-gray-100 hidden w-96 overflow-y-auto border-r border-gray-200 px-4 py-6 pt-16 sm:px-6 lg:px-8 xl:block" : "")}>
            {aside}
        </aside>
    </div>
</>}
