import {DialogTitle} from "@headlessui/react";

export const ExpensesStepPage = ({smallestExpense, setSmallestExpense, biggestExpense, setBiggestExpense}) => <div>
    <div className="mt-3 sm:mt-5">
        <DialogTitle as="h2" className="text-base font-semibold leading-6 text-gray-900">
            What monthly expenses do you want to cover from your passive income?
        </DialogTitle>

        <form>
            <div className="space-y-12">

                <div className="border-b border-gray-900/10 pb-12">
                    {/*<p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>*/}

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4 sm:col-start-1">
                            <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                                Your smallest monthly expense? (i.e. Netflix, phone bill, etc)
                            </label>
                            <div className="mt-2">
                                <input
                                    id="city"
                                    name="city"
                                    value={smallestExpense.name}
                                    onChange={(e) => setSmallestExpense({
                                        ...smallestExpense,
                                        name: e.target.value
                                    })}
                                    placeholder={"Utility bill"}
                                    type="text"
                                    autoComplete="address-level2"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                                Cost
                            </label>
                            <div className="mt-2">
                                <input
                                    id="region"
                                    name="region"
                                    value={smallestExpense.amount}
                                    onChange={(e) => setSmallestExpense({
                                        ...smallestExpense,
                                        amount: e.target.value
                                    })}
                                    placeholder={"300"}
                                    type="number"
                                    autoComplete="address-level1"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </div>


                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4 sm:col-start-1">
                            <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                                Your biggest monthly expense?
                            </label>
                            <div className="mt-2">
                                <input
                                    id="city"
                                    name="city"
                                    value={biggestExpense.name}
                                    onChange={(e) => setBiggestExpense({
                                        ...biggestExpense,
                                        name: e.target.value
                                    })}
                                    placeholder={"Mortgage, rent, etc."}
                                    type="text"
                                    autoComplete="address-level2"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                                Cost
                            </label>
                            <div className="mt-2">
                                <input
                                    id="region"
                                    name="region"
                                    value={biggestExpense.amount}
                                    onChange={(e) => setBiggestExpense({
                                        ...biggestExpense,
                                        amount: e.target.value
                                    })}
                                    placeholder={"2000"}
                                    type="number"
                                    autoComplete="address-level1"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                    </div>


                    {/*<div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">*/}
                    {/*    <div className="sm:col-span-4 sm:col-start-1">*/}
                    {/*        <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">*/}
                    {/*            Anything else (i.e. groceries, utility bills, etc)*/}
                    {/*        </label>*/}
                    {/*        <div className="mt-2">*/}
                    {/*            <input*/}
                    {/*                id="city"*/}
                    {/*                name="city"*/}
                    {/*                type="text"*/}
                    {/*                autoComplete="address-level2"*/}
                    {/*                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}


                    {/*    <div className="sm:col-span-2">*/}
                    {/*        <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">*/}
                    {/*            Amount*/}
                    {/*        </label>*/}
                    {/*        <div className="mt-2">*/}
                    {/*            <input*/}
                    {/*                id="region"*/}
                    {/*                name="region"*/}
                    {/*                type="number"*/}
                    {/*                autoComplete="address-level1"*/}
                    {/*                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

            </div>

        </form>
    </div>
</div>
