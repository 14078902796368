import {BanknotesIcon, BuildingOfficeIcon, ChartPieIcon, UserIcon} from "@heroicons/react/16/solid";

const tabs = [
    { name: 'All Expenses', href: '#', icon: BanknotesIcon },
    { name: 'Expenses Chart', href: '#', icon: ChartPieIcon },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ExpenseTabs({currentTab, setCurrentTab}) {
    return (
        <div className={"mb-7 mt-2"}>
            <div className="sm:hidden mt-5">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                    id="tabs"
                    name="tabs"
                    onChange={(e) => {
                        setCurrentTab(e.target.value)
                    }}
                    defaultValue={tabs.find((tab) => currentTab == tab.name).name}
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                >
                    {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                    <nav aria-label="Tabs" className="-mb-px flex space-x-8">
                        {tabs.map((tab) => (
                            <a
                                key={tab.name}
                                href={tab.href}
                                onClick={() => setCurrentTab(tab.name)}
                                aria-current={currentTab == tab.name ? 'page' : undefined}
                                className={classNames(
                                    currentTab == tab.name
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                    'group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium',
                                )}
                            >
                                <tab.icon
                                    aria-hidden="true"
                                    className={classNames(
                                        currentTab == tab.name ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                                        '-ml-0.5 mr-2 h-5 w-5',
                                    )}
                                />
                                <span>{tab.name}</span>
                            </a>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
    )
}
