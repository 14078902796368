import {CheckIcon} from '@heroicons/react/20/solid'
import {MoveToTop} from "./MoveToTop";
import {dbExpensesToSteps, expensesTotal, formatMoney} from "../utils/utils";
import useUserDataStore from "../hooks/useUserDataStore";
import {useState} from "react";
import Toggle from "../dummy-components/Toggle";
import {reverse} from "lodash";
import useLocalState from "../hooks/useLocalState";
import {
    BanknotesIcon, BuildingLibraryIcon, ChevronDownIcon, LightBulbIcon,
    MegaphoneIcon, PlusIcon, ShoppingBagIcon
} from "@heroicons/react/16/solid";
import {ExpenseButtons} from "./Expenses/Buttons";
import * as PropTypes from "prop-types";
import ModeSelector from "./ModeSelector";
import {ExpensesChart} from "./ExpensesChart";
import { Tooltip } from 'react-tooltip'
import ExpenseTabs from "./ExpenseTabs";
import {ExpensesPie} from "./ExpensesPie";
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function ExpenseName({step}) {
    return <span className="text-sm font-medium text-gray-500">{step.name} {step.expenseType != 'recurring' ?
        <><span data-tooltip-id={step.name} data-tooltip-html="Variable expense, calculated as a 12-month average <br/> due to monthly fluctuations.">
                            📊
                        </span>
            <Tooltip id={step.name} style={{"text-align": "center"}} />
        </>
        : ''}</span>
}



function TotalExpenseSectionButtons(props) {
    console.log("TotalExpenseSectionButtons:props", props);

    return <>
        <button
            onClick={props.mode == 'demo' ? () => {} : props.onClick}
            type="button"
            className={classNames(
        "mr-3 rounded-full bg-white px-2.5 py-1 text-xs font-semibold shadow-sm ring-1 ring-inset ", props.mode == "demo" ? "ring-gray-300 text-gray-400" : "text-green-900 ring-green-300 hover:bg-green-50")}
        >
            Add Investment
        </button>
        <button
            onClick={props.onClick1}
            type="button"
            className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-indigo-900 shadow-sm ring-1 ring-inset ring-indigo-300 hover:bg-indigo-50"
        >
            Add Expense Category
        </button>
    </>;
}

function Actions(props) {
    return <Menu as="div" className="relative inline-block text-left">
        <div>
            <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                Actions
                <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
            </MenuButton>
        </div>

        <MenuItems
            transition
            className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
            <div className="py-1">
                <MenuItem className="group">
                    <a
                        href="#"
                        onClick={props.onClick}
                        className="group flex items-center px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                    >
                        <BuildingLibraryIcon aria-hidden="true" className="mr-3 h-5 w-5 text-gray-400 group-data-[focus]:text-gray-500"/>
                        Add Investment
                    </a>
                </MenuItem>
            </div>
            <div className="py-1">
                <MenuItem className="group">
                    <a
                        href="#"
                        onClick={props.onClick1}
                        className="group flex items-center px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                    >
                        <BanknotesIcon aria-hidden="true" className="mr-3 h-5 w-5 text-gray-400 group-data-[focus]:text-gray-500"/>
                        Add Expense Category
                    </a>
                </MenuItem>
            </div>
        </MenuItems>
    </Menu>
}

TotalExpenseSectionButtons.propTypes = {
    onClick: PropTypes.func,
    onClick1: PropTypes.func
};
export default function Expenses({
                                     mode,
                                     setIsInvestmentPlanSidebarOpen,
                                     expenseStore,
                                     monthlyPassiveIncome,
                                     setIsGuidePopupOpen,
                                     openDeleteExpenseDialog,
                                     expenses,
                                     topExpense,
                                     expensesAsSteps,
                                     updateExpense,
    cumulative,
    setCumulative,
                                     openExpenseForm,
                                 }) {
    const [reverseSort, setReverseSort] = useState(true)
    const [currentTab, setCurrentTab] = useState('All Expenses')

    console.log('Expenses:monthlyPassiveIncome', monthlyPassiveIncome)

    // Total monthly expenses
    const total = expensesTotal(expensesAsSteps)

    // Reversed expenses
    const reversedExpenses = expensesAsSteps.slice().reverse()
    const chartData = Object.fromEntries(reversedExpenses.map((expense) => {
        return [expense.rawName, {amountCovered: expense.amountCovered, amountLeftToCover: expense.amountLeftToCover}]
    }))

    return (
        <nav aria-label="Progress">
            {/*<div style={{height: '400px', width: '100%'}}><ExpensesChart inputData={chartData}/></div>*/}
            {/* Total expenses section */}
            <div className="flex justify-between items-center">
                <h2 className="text-lg font-medium text-gray-900">Total monthly expenses: {formatMoney(total)}</h2>

                <div className="hidden lg:block">
                    <TotalExpenseSectionButtons mode={mode} onClick={() => {
                        setIsInvestmentPlanSidebarOpen(true)
                    }} onClick1={() => {
                        openExpenseForm(true)
                    }}/>
                </div>

                <div className="lg:hidden">
                    <Actions onClick={() => {
                        setIsInvestmentPlanSidebarOpen(true)
                    }} onClick1={() => {
                        openExpenseForm(true)
                    }} />
                </div>
            </div>

            {/*<blockquote style={{display: "none"}}>*/}
            {/*    <div className="mb-1">*/}
            {/*        I want to cover <span>100%</span> of my expenses how much do I need to have invested?*/}
            {/*    </div>*/}
            {/*    <div className="mb-1">*/}
            {/*        I want to cover <span>Groceries</span> expenses how much do I need to have invested?*/}
            {/*    </div>*/}
            {/*    <div className="mb-1">*/}
            {/*        By investing <span>$1000</span> how quickly I can cover 100% of my expenses with average market*/}
            {/*        returns?*/}
            {/*    </div>*/}
            {/*</blockquote>*/}


            {/*<hr className="mb-5"/>*/}

            {/*{expenses.length ? <div className="mb-5 inline-flex">*/}
            {/*    /!*<div className="flex items-top text-sm font-normal mr-3">*!/*/}
            {/*    /!*    <span className="ml-2"*!/*/}
            {/*    /!*                           style={{"position": "relative", "top": "-8px"}}*!/*/}
            {/*    /!*>*!/*/}
            {/*    /!*<ModeSelector options={[*!/*/}
            {/*    /!*    { title: 'Cover expenses from smallest to largest',*!/*/}
            {/*    /!*        description: "I'm aiming to cover all expenses starting from smallest to largest.",*!/*/}
            {/*    /!*        current: cumulative == true,*!/*/}
            {/*    /!*        onSelect: () => {*!/*/}
            {/*    /!*            console.log('Cumulative mode: true')*!/*/}
            {/*    /!*            setCumulative(true)*!/*/}
            {/*    /!*        },*!/*/}
            {/*    /!*    },*!/*/}
            {/*    /!*    { title: 'Cover each expense individually',*!/*/}
            {/*    /!*    description: "I'm aiming to cover each expense individually first.",*!/*/}
            {/*    /!*        current: cumulative == false,*!/*/}
            {/*    /!*        onSelect: () => setCumulative(false),},*!/*/}
            {/*    /!*]}/>*!/*/}
            {/*    /!*</span>*!/*/}
            {/*    /!*</div>*!/*/}


            {/*    /!*<div className="flex items-top text-sm font-normal mr-3">*!/*/}
            {/*    /!*    Cumulative mode: <span className="ml-2"*!/*/}
            {/*    /!*                           style={{"position": "relative", "top": "-2px"}}*!/*/}
            {/*    /!*><Toggle enabled={cumulative} setEnabled={setCumulative}/></span>*!/*/}
            {/*    /!*</div>*!/*/}

            {/*    /!*<div className="flex items-top text-sm font-normal">*!/*/}
            {/*    /!*    Largest expenses first: <span className="ml-2"*!/*/}
            {/*    /!*                                  style={{"position": "relative", "top": "-2px"}}*!/*/}
            {/*    /!*><Toggle enabled={reverseSort} setEnabled={setReverseSort}/></span>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*</div> : null}*/}

            {!expenses.length ? <div className="text-center py-10">
                <BanknotesIcon className={"mx-auto h-12 w-12 text-gray-400"}/>
                <h3 className="mt-2 text-sm font-semibold text-gray-900">No expenses</h3>
                <p className="mt-1 text-sm text-gray-500">Get started by logging you first expense</p>
                <div className="mt-6">
                    <button
                        onClick={() => {
                            openExpenseForm(true)
                        }}
                        type="button"
                        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5"/>
                        Add Expense Category
                    </button>
                </div>
            </div> : null}

            <ExpenseTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
            {currentTab === 'All Expenses' && (
                <ol role="list" className="overflow-hidden">
                    {(reverseSort ? expensesAsSteps.slice().reverse() : expensesAsSteps).map((step, stepIdx) => (
                        <li key={step.name}
                            className={classNames(stepIdx !== expensesAsSteps.length - 1 ? 'pb-10' : '', 'relative', 'li-expense')}>
                            {step.status === 'complete' ? (
                                <>
                                    {stepIdx !== expensesAsSteps.length - 1 ? (
                                        <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-green-600"
                                             aria-hidden="true"/>
                                    ) : null}
                                    <a href={step.href}
                                       onClick={
                                           (e) => {
                                               e.preventDefault()
                                               e.stopPropagation()

                                               updateExpense(step.expenseId)
                                           }}
                                       className="group relative flex items-start cursor-pointer">
                  <span className="flex h-9 items-center">
                    <span
                        className="relative z-0 flex h-8 w-8 items-center justify-center rounded-full bg-green-600 group-hover:bg-green-800">
                      <CheckIcon className="h-5 w-5 text-white" aria-hidden="true"/>
                    </span>
                  </span>

                                        <span className="ml-4 flex min-w-0 flex-col">
                                        <ExpenseName step={step} />
                    <span className="text-sm text-gray-500">{step.description}</span>
                                    </span>

                                        <ExpenseButtons cumulative={cumulative} step={step}
                                                        openDeleteExpenseDialog={openDeleteExpenseDialog}/>
                                    </a>
                                </>
                            ) : step.status === 'current' ? (
                                <>
                                    {stepIdx !== expensesAsSteps.length - 1 ? (
                                        <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                                             aria-hidden="true"/>
                                    ) : null}
                                    <a href={step.href}
                                       onClick={(e) => {
                                           e.preventDefault()
                                           e.stopPropagation()

                                           updateExpense(step.expenseId)
                                       }}
                                       className="group relative flex items-start cursor-pointer" aria-current="step">
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span
                        className="relative z-0 flex h-8 w-8 items-center justify-center rounded-full border-2 border-green-600 bg-white">

                        <span className="h-2.5 w-2.5 rounded-full bg-green-600"/>
                    </span>
                  </span>


                                        <span className="ml-4 flex min-w-0 flex-col">
                                        <ExpenseName step={step} />
                    <span className="text-sm text-gray-500">{step.description}</span>
                  </span>

                                        <ExpenseButtons step={step} showGuide={(e) => {
                                            expenseStore.controls.selectExpense(step)
                                            setIsGuidePopupOpen(true)
                                        }}
                                                        cumulative={cumulative}
                                                        openDeleteExpenseDialog={openDeleteExpenseDialog}/>
                                    </a>
                                </>
                            ) : (
                                <>
                                    {stepIdx !== expensesAsSteps.length - 1 ? (
                                        <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                                             aria-hidden="true"/>
                                    ) : null}
                                    <a href={step.href}
                                       onClick={(e) => {
                                           e.preventDefault()
                                           e.stopPropagation()

                                           updateExpense(step.expenseId)
                                       }}
                                       className="group relative flex items-start cursor-pointer">
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span
                        className="relative z-0 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                      <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"/>
                    </span>
                  </span>
                                        <span className="ml-4 flex min-w-0 flex-col">
                                        <ExpenseName step={step} />
                    <span className="text-sm text-gray-500">{step.description}</span>
                  </span>

                                        <ExpenseButtons step={step} showGuide={(e) => {
                                            expenseStore.controls.selectExpense(step)
                                            setIsGuidePopupOpen(true)
                                        }}
                                                        cumulative={cumulative}
                                                        openDeleteExpenseDialog={openDeleteExpenseDialog}/>
                                    </a>
                                </>
                            )}
                        </li>
                    ))}
                </ol>)
            }
            {currentTab === 'Expenses Chart' && (
                <div className={"sm:w-1/2 m-auto"}>
                    <ExpensesPie expensesAsSteps={expensesAsSteps} />
                </div>
            )}
        </nav>
    )
}
