
import {Dialog, DialogBackdrop, DialogPanel, DialogTitle} from "@headlessui/react";
import {LightBulbIcon} from "@heroicons/react/16/solid";
import {formatMoney, getLastEntry, getMeanEntry} from "../utils/utils";
import {formatDistance, addMonths} from "date-fns";

const getExtraAmountToCover = (currentExpense, expensesAsSteps, cumulative) => {
    if (!cumulative) {
        return 0
    }

    const index = expensesAsSteps.findIndex((expense) => {
        return expense.expenseId == currentExpense.expenseId
    })

    const prevExpenses = expensesAsSteps.slice(0, index)
    console.log('GuidePopup:prevExpenses', prevExpenses)
    console.log('GuidePopup:prevExpenses', prevExpenses.map((expense) => expense.name))

    const extraAmountNeeded = expensesAsSteps.slice(0, index).reduce((acc, expense) => {
        return acc + expense.numericPrice
    }, 0)

    console.log('GuidePopup:extraAmountNeeded', extraAmountNeeded)

    return extraAmountNeeded
}

export default function GuidePopup({userData,portfolioNavStore, monthlyPassiveIncome,expenseStore, isOpen, setIsOpen, cumulative, expensesAsSteps}) {
    if (!expenseStore || !expenseStore?.currentExpense) {
        return null
    }

    console.log('GuidePopup:userData', userData)

    const portfolioValue = getLastEntry(portfolioNavStore.navUpdates)
    console.log('GuidePopup', portfolioValue)
    console.log('GuidePopup:investmentPlanAmount', userData.investmentPlanAmount)

    const getMean = getMeanEntry(expenseStore.currentExpense.entries) / 100
    const currentExpense = expenseStore.currentExpense
    console.log('GuidePopup:currentExpense', currentExpense)
    console.log('GuidePopup:getMean', getMean)

    const amountToCover = getMean + getExtraAmountToCover(currentExpense, expensesAsSteps, cumulative)

    const amountNeeded = (amountToCover)  * 12 / (userData.yield/100) - portfolioValue.amountInCents / 100
    console.log('GuidePopup:amountToCover', amountToCover)
    console.log('GuidePopup:monthlyPassiveIncome', monthlyPassiveIncome)

    const monthsNeeded = Math.ceil(amountNeeded / (userData.investmentPlanAmount || 1))
    console.log('GuidePopup:monthsNeeded', monthsNeeded)
    let timeDistance = "unknown amount of time"
    try {
        timeDistance = formatDistance(new Date(), addMonths(new Date(), monthsNeeded))
    } catch (e) {}

    return (
        <Dialog open={isOpen} onClose={setIsOpen} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="sm:flex sm:items-start">
                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                                <LightBulbIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                            </div>
                            <div className="pt-2 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                    On average you spend {formatMoney(getMean)}&nbsp;
                                    on "{expenseStore.currentExpense.rawName}" monthly
                                </DialogTitle>
                                <div className="mt-5">
                                    <p className="text-sm text-gray-500">
                                        Monthly you generate <b>{formatMoney(monthlyPassiveIncome)}</b> in monthly passive income. But you need to generate <b>{formatMoney(amountToCover - monthlyPassiveIncome)}</b> more.
                                    </p>
                                    <br />
                                    <p className="text-sm text-gray-500">
                                        To completely cover this expense (including all expenses below) through investments, you need an additional <b>{formatMoney(amountNeeded)}</b> invested

                                        <br />
                                        <br />
                                        By investing <b>{formatMoney(userData.investmentPlanAmount)}</b> per month, it will take <b>{timeDistance}</b> to reach this goal.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                onClick={() => setIsOpen(false)}
                                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                            >
                                Close
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}
