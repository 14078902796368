import {DialogTitle} from "@headlessui/react";


export const InvestmentsStepPage = ({passiveIncome, setPassiveIncome, investments, setInvestments}) => <div>
    <div className="mt-3 sm:mt-5">
        <DialogTitle as="h2" className="text-base font-semibold leading-6 text-gray-900">
            What investments do you have?
        </DialogTitle>

        <form>
            <div className="space-y-12">

                <div className="border-b border-gray-900/10 pb-12">
                    <p className="mt-1 text-sm leading-6 text-gray-600">If you don't have any invesments yet, put what is your target after 1 year.</p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-6 sm:col-start-1">
                            <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                                What is your current value of investments portfolio? (Stock, bonds, ETFs)
                            </label>
                            <div className="mt-2 max-w-64">
                                <input
                                    id="region"
                                    name="region"
                                    value={investments.amount}
                                    onChange={(e) => setInvestments({
                                        ...investments,
                                        amount: e.target.value
                                    })}
                                    placeholder={"50000"}
                                    type="number"
                                    autoComplete="address-level1"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        {/*<div className="sm:col-span-4 ">*/}
                        {/*    <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">*/}
                        {/*        Investments (Portfolio of stocks, bonds, ETFs)*/}
                        {/*    </label>*/}
                        {/*    <div className="mt-2">*/}
                        {/*        <input*/}
                        {/*            id="city"*/}
                        {/*            name="city"*/}
                        {/*            type="text"*/}
                        {/*            value={investments.name}*/}
                        {/*            onChange={(e) => setInvestments({*/}
                        {/*                ...investments,*/}
                        {/*                name: e.target.value*/}
                        {/*            })}*/}
                        {/*            placeholder={"Stocks, bonds, ETFs, etc."}*/}
                        {/*            autoComplete="address-level2"*/}
                        {/*            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>


                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-6 sm:col-start-1">
                            <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                                What amount of passive income you get on monthly basis? (rental income, fixed income)
                            </label>
                            <div className="mt-2 max-w-64">
                                <input
                                    id="region"
                                    name="region"
                                    value={passiveIncome.amount}
                                    onChange={(e) => setPassiveIncome({
                                        ...passiveIncome,
                                        amount: e.target.value
                                    })}
                                    placeholder={"1000"}
                                    type="number"
                                    autoComplete="address-level1"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        {/*<div className="sm:col-span-4 ">*/}
                        {/*    <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">*/}
                        {/*        Monthly passive income (i.e. rental income, fixed income)*/}
                        {/*    </label>*/}
                        {/*    <div className="mt-2">*/}
                        {/*        <input*/}
                        {/*            id="city"*/}
                        {/*            name="city"*/}
                        {/*            value={passiveIncome.name}*/}
                        {/*            onChange={(e) => setPassiveIncome({*/}
                        {/*                ...passiveIncome,*/}
                        {/*                name: e.target.value*/}
                        {/*            })}*/}
                        {/*            placeholder={"Rental income"}*/}
                        {/*            type="text"*/}
                        {/*            autoComplete="address-level2"*/}
                        {/*            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}


                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
