import {useAuth} from "../providers/authprovider";
import {useCallback, useEffect, useState} from "react";
import {arrayUnion, doc, getDoc, setDoc, updateDoc} from "firebase/firestore";
import {db} from "../firebaseInit";
import { v4 as uuidv4 } from 'uuid';
import {getLastEntry} from "../utils/utils";
import useLocalState from "./useLocalState";

const useUserDataStore = () => {
    const [userData, setUserData] = useLocalState('userSettings', { yield: 4, investmentPlanAmount:800 });

    const syncUserData = async (data) => {
        setUserData(data)
    };

    const updateInvestmentPlanAmount = async (amountInMajorCurrency) => {
        syncUserData(
            {
                ...userData,
                yield: 4,
                investmentPlanAmount: amountInMajorCurrency
            }
        )
    }

    const addInvestmentPlanEntry = async ({costInCents, loggedAt, portfolioNavStore}) => {

    }

    const removeInvestmentPlanEntry = async ({uuid, costInCents, portfolioNavStore}) => {

    }

    return {userData, syncUserData, addInvestmentPlanEntry, removeInvestmentPlanEntry, updateInvestmentPlanAmount};
};

export default useUserDataStore;
