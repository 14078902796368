import React from 'react';
import {Bar, Pie} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Colors
} from 'chart.js';

// Register the components to be used in the chart
ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors);

// Example input data:
// {
//  "YouTube": {amountCovered: 10, amountLeftToCover: 0},
//  "Morgage": {amountCovered: 28000, amountLeftToCover: 1000},
// }
export const ExpensesPie = ({expensesAsSteps}) => {
    const expenses = expensesAsSteps.slice().reverse()
    const top6 = expenses.slice(0, 6)
    const others = expenses.slice(6)
    const totalExpenses = others.length == 0 ? top6 : top6.concat({
        rawName: 'Others',
        numericPrice: others.reduce((acc, curr) => acc + curr.numericPrice, 0)
    })


    console.log('ExpensesPie: expensesAsSteps', expenses)
    // Data for the stacked bar chart
    const data = {
        labels: totalExpenses.map(x => x.rawName),
        datasets: [{
            data: totalExpenses.map(x => x.numericPrice),
            hoverOffset: 4,
        }]
    };

    // Configuration for the chart
    const options = {
        plugins: {
            colors: {
                forceOverride: true,
                enabled: true
            }
        }
    };

    return <Pie data={data} options={options}  />;
};

