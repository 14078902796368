import {Dialog, DialogPanel, DialogTitle} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {useState} from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {addWeeks} from "date-fns";
import {SubmitButton} from "./Button";

const expenseTypes = [
    { id: 'recurring', name: 'Recurring', description: 'These are predictable costs that remain consistent month to month, such as rent, mortgage, or car payments.' },
    // { id: 'variable', name: 'Variable', description: 'These are expenses that vary from month to month, like groceries or shopping' },
]

export const NewExpenseSidebar = ({isOpen, setIsOpen, controls}) => {
    const [newExpenseCost, setNewExpenseCost] = useState(0)
    const [newExpenseName, setNewExpenseName] = useState('')
    const [month, setMonth] = useState(new Date().getMonth())
    const [year, setYear] = useState(new Date().getFullYear())
    const [expenseType, setExpenseType] = useState('variable')

    const years = Array.from({length: 5}, (_, i) => new Date().getFullYear() - i)

    const storeExpense = async (e) => {
        e.preventDefault()

        // TODO: validate cost

        try {
            const loggedAt = addWeeks(new Date(year, month), 1)
            console.log('NewExpenseSidebar:storeExpense:', newExpenseName, newExpenseCost, loggedAt)
            await controls.addExpense({newExpenseName, newExpenseCost, loggedAt, expenseType})
            setNewExpenseCost(0)
            setNewExpenseName('')
            setIsOpen(false)
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    }

    return <Dialog open={isOpen} onClose={setIsOpen} className="relative z-40">
        <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                    <DialogPanel
                        transition
                        className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                    >
                        <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                            <div className="h-0 flex-1 overflow-y-auto">
                                <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                                    <div className="flex items-center justify-between">
                                        <DialogTitle className="text-base font-semibold leading-6 text-white">💵 New Expense Category</DialogTitle>
                                        <div className="ml-3 flex h-7 items-center">
                                            <button
                                                type="button"
                                                onClick={() => setIsOpen(false)}
                                                className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                            >
                                                <span className="absolute -inset-2.5" />
                                                <span className="sr-only">Close panel</span>
                                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mt-1">
                                        <p className="text-sm text-indigo-300">
                                            Get started by filling in the information below to create your new expense category.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-1 flex-col justify-between">
                                    <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                        <div className="space-y-6 pb-5 pt-6">
                                            <div>
                                                <label htmlFor="project-name" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Expense Category Name
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="project-name"
                                                        name="project-name"
                                                        type="text"
                                                        value={newExpenseName}
                                                        onChange={(e) => setNewExpenseName(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                    How much you spend monthly on it?
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="value"
                                                        name="value"
                                                        type="number"
                                                        value={newExpenseCost}
                                                        onChange={(e) => setNewExpenseCost(e.target.value)}
                                                        rows={4}
                                                        className="ddblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Month / Year
                                                </label>
                                                <div className="mt-2 flex">
                                                    <select
                                                        id="location"
                                                        name="location"
                                                        value={month}
                                                        onChange={(e) => setMonth(e.target.value)}
                                                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    >
                                                        <option value={0}>01</option>
                                                        <option value={1}>02</option>
                                                        <option value={2}>03</option>
                                                        <option value={3}>04</option>
                                                        <option value={4}>05</option>
                                                        <option value={5}>06</option>
                                                        <option value={6}>07</option>
                                                        <option value={7}>08</option>
                                                        <option value={8}>09</option>
                                                        <option value={9}>10</option>
                                                        <option value={10}>11</option>
                                                        <option value={11}>12</option>
                                                    </select>

                                                    <div style={{"padding-top": "0.7rem"}}>
                                                        &nbsp;/&nbsp;
                                                    </div>

                                                    <select
                                                        id="location"
                                                        name="location"
                                                        value={year}
                                                        onChange={(e) => {
                                                            setYear(e.target.value)
                                                        }}
                                                        className="mt-2 min-w-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    >
                                                        {years.map((year) => (
                                                            <option value={year}>{year}</option>
                                                        ))}
                                                    </select>

                                                    {/*<DatePicker*/}
                                                    {/*    className="ddblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
                                                    {/*    selected={loggedAt.startDate}*/}
                                                    {/*    onChange={(date) => handleValueChange({startDate: date})}*/}
                                                    {/*/>*/}
                                                </div>
                                            </div>
                                            <div>
                                                <fieldset aria-label="Plan">
                                                    <div className="space-y-5">
                                                        {expenseTypes.map((type) => (
                                                            <div key={type.id} className="relative flex items-start">
                                                                <div className="flex h-6 items-center">
                                                                    <input
                                                                        checked={type.id === expenseType}
                                                                        onChange={() => expenseType == 'recurring' ? setExpenseType('variable') : setExpenseType('recurring')}
                                                                        id={type.id}
                                                                        name="plan"
                                                                        type="checkbox"
                                                                        aria-describedby={`${type.id}-description`}
                                                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                                    />
                                                                </div>
                                                                <div className="ml-3 text-sm leading-6">
                                                                    <label htmlFor={type.id} className="font-medium text-gray-900">
                                                                        {type.name}
                                                                    </label>{' '}
                                                                    <span id={`${type.id}-description`} className="text-gray-500">
                {type.description}
              </span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </fieldset>


                                                {/*<div className="mt-2">*/}
                                                {/*    <select*/}
                                                {/*        id="location"*/}
                                                {/*        name="location"*/}
                                                {/*        value={month}*/}
                                                {/*        onChange={(e) => setMonth(e.target.value)}*/}
                                                {/*        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
                                                {/*    >*/}
                                                {/*        <option value={"STABLE"}>Stable (Mortgage, Rent, etc)</option>*/}
                                                {/*        <option value={"FLEXIBLE"}>Flexible (Groceries, Shopping, etc)</option>*/}
                                                {/*    </select>*/}
                                                {/*</div>*/}
                                                {/*<br/>*/}
                                                {/*<p className="text-sm text-gray-900 bg-indigo-50 rounded-sm px-3 py-3 font-strong">*/}
                                                {/*    /!*<div className={"font-bold"}>*!/*/}
                                                {/*    /!*    💡 Recurring expenses*!/*/}
                                                {/*    /!*</div>*!/*/}
                                                {/*    <div className="pl-5 pt-2">*/}
                                                {/*        📊 <b>Variable Expenses</b>*/}
                                                {/*        <br/>*/}
                                                {/*        These are expenses that vary from month to month, like groceries or shopping. For these, you can update the amounts each month. In calculations, the app will automatically use the average of your last 12 months of data to give a more accurate reflection of your spending trends.*/}
                                                {/*        <br/>*/}
                                                {/*        <br/>*/}

                                                {/*        🔃 <b>Recurring Expenses</b>*/}
                                                {/*        <br/>*/}
                                                {/*        Since the amounts rarely change, you only need to update them when there’s an actual change in the amount. The app will use the most recent value you’ve entered in all calculations.*/}
                                                {/*    </div>*/}
                                                {/*</p>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                <button
                                    type="button"
                                    onClick={() => setIsOpen(false)}
                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    Cancel
                                </button>
                                <SubmitButton
                                    text={"Save"}
                                    loadingText={"Saving..."}
                                    onSubmit={storeExpense}
                                />
                            </div>
                        </form>
                    </DialogPanel>
                </div>
            </div>
        </div>
    </Dialog>
}
