import { useState, useEffect } from 'react';
import {collection, query, where, getDocs, doc, deleteDoc, updateDoc, arrayUnion, arrayRemove, addDoc} from "firebase/firestore";
import { db } from '../firebaseInit';
import { v4 as uuidv4 } from 'uuid';
import {toDateTime} from "../utils/utils";

const useExpenses = (accountId) => {
    const [expenses, setExpenses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cacheLastInvalidatedAt, setCacheLastInvalidatedAt] = useState(Date.now());
    const [currentExpense, setCurrentExpense] = useState(null)

    useEffect(() => {
        const fetchExpenses = async () => {
            try {
                const q = query(collection(db, "expenses"), where("userId", "==", accountId));

                const querySnapshot = await await getDocs(q);

                const expensesList = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                console.log('useExpenses expensesList: ', expensesList)

                setExpenses(expensesList);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching expenses: ", error);
                setLoading(false);
            }
        };

        if (accountId) {
            fetchExpenses();
        }
    }, [accountId, cacheLastInvalidatedAt]);

    const selectExpense = (expense) => {
        setCurrentExpense(expense)
        console.log('useUpdateExpenseSidebar:selectExpense:', expense)
    }

    const save = async ({costInCents, loggedAt, expenseType}) => {
        const docRef = doc(db, "expenses", currentExpense.id);
        console.log('useExpenses:save:', docRef, costInCents)
        const entryToDelete = currentExpense.entries.find(entry => {
            const loggedAtDate = toDateTime(entry.loggedAt.seconds)
            const loggedAtMonth = loggedAtDate.getMonth()
            const loggedAtYear = loggedAtDate.getFullYear()

            const newLoggedAtMonth = loggedAt.getMonth()
            const newLoggedAtYear = loggedAt.getFullYear()
            console.log('useExpenses:save:entryToDelete:', loggedAtDate, loggedAtMonth, loggedAtYear, newLoggedAtMonth, newLoggedAtYear)

            return loggedAtMonth === newLoggedAtMonth && loggedAtYear === newLoggedAtYear
        })
        const newEntry = {
            uuid: uuidv4(),
            costInCents,
            loggedAt: new Date(loggedAt)
        }
        const newEntries = [...currentExpense.entries.filter(entry => entry.uuid !== entryToDelete?.uuid), newEntry]
        var updateDocument = {
            entries: newEntries
        }
        updateDoc.expenseType = expenseType
        await updateDoc(docRef, updateDocument);
        setCurrentExpense(null)
        setCacheLastInvalidatedAt(Date.now())
    }

    const removeEntry = async ({expenseId, uuid}) => {
        const docRef = doc(db, "expenses", expenseId);
        console.log('useExpenses:removeEntry:', docRef, expenseId, uuid)
        const newEntries = currentExpense.entries.filter(entry => entry.uuid !== uuid)
        await updateDoc(docRef, {
            entries: newEntries
        });
        setCurrentExpense({
            ...currentExpense,
            entries: newEntries
        })
        setCacheLastInvalidatedAt(Date.now())
    }

    const deleteExpense = async (expenseId) => {
        const docRef = doc(db, "expenses", expenseId);
        console.log('useExpenses:deleteExpense:', docRef)
        await deleteDoc(docRef);
        setCacheLastInvalidatedAt(Date.now())
    }

    const addExpense = async ({newExpenseName, newExpenseCost, loggedAt, expenseType}) => {
        await addDoc(collection(db, "expenses"), {
            userId: accountId,
            name: newExpenseName,
            expenseType,
            entries: [{
                uuid: uuidv4(),
                costInCents: newExpenseCost*100,
                loggedAt: loggedAt,
            }]
        });
        setCacheLastInvalidatedAt(Date.now())
    }

    return { expenses, currentExpense, controls: {selectExpense, save, deleteExpense, addExpense, removeEntry} };
};

export default useExpenses;
