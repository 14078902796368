import {Dialog, DialogPanel, DialogTitle} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import Datepicker from "react-tailwindcss-datepicker";
import {useEffect, useState} from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {decodeLoggedAt, formatMoney, toDateTime} from "../utils/utils";
import classNames from "classnames";
import {formatDate} from "date-fns";
import {SubmitButton} from "./Button";



export const ExepenseUpdateSidebar = ({expense, controls, isOpen, setIsOpen}) => {

    console.log('ExepenseUpdateSidebar expense:', expense)
    console.log('ExepenseUpdateSidebar controls:', controls)
    console.log('ExepenseUpdateSidebar isOpen:', isOpen)

    const sortedEntriesDesc = expense?.entries?.sort((a,b) => decodeLoggedAt(b.loggedAt) - decodeLoggedAt(a.loggedAt)) || []
    const [newExpenseCost, setNewExpenseCost] = useState(!expense ? 0 : sortedEntriesDesc[0].costInCents / 100)
    const [month, setMonth] = useState(new Date().getMonth())
    const [year, setYear] = useState(new Date().getFullYear())
    const [confirmDeleteEntryTimestamp, setConfirmDeleteEntryTimestamp] = useState(null)
    const [expenseType, setExpenseType] = useState(expense?.expenseType)

    const years = Array.from({length: 5}, (_, i) => new Date().getFullYear() - i)

    useEffect(() => {
        setExpenseType(expense?.expenseType)
        setNewExpenseCost(!expense ? 0 : sortedEntriesDesc[0].costInCents / 100)
    }, [expense])

    if (!expense) {
        return null
    }

    console.log('ExepenseUpdateSidebar expenseType:', expenseType)

    return <Dialog open={isOpen} onClose={setIsOpen} className="relative z-40">
        <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <DialogPanel
                    transition
                    className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                >
                    <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                        <div className="h-0 flex-1 overflow-y-auto">
                            <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                                <div className="flex items-center justify-between">
                                    <DialogTitle className="text-base font-semibold leading-6 text-white">{expense.name}</DialogTitle>
                                    <div className="ml-3 flex h-7 items-center">
                                        <button
                                            type="button"
                                            onClick={() => setIsOpen(false)}
                                            className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                        >
                                            <span className="absolute -inset-2.5" />
                                            <span className="sr-only">Close panel</span>
                                            <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                        </button>
                                    </div>
                                </div>
                                <div className="mt-1">
                                    <p className="text-sm text-indigo-300">
                                        Wanna update the cost of this expense?
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                                <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                    <div className="space-y-6 pb-5 pt-6">
                                        <div>
                                            <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                How much you spend monthly on it?
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="value"
                                                    name="value"
                                                    type="number"
                                                    value={newExpenseCost}
                                                    onChange={(e) => setNewExpenseCost(e.target.value)}
                                                    rows={4}
                                                    className="ddblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    defaultValue={0}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                                <div className="divide-y pb-6 divide-gray-200 px-4 sm:px-6">
                                    <div className="space-y-6 pb-5 pt-6">
                                        <div>
                                            <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                Month / Year
                                            </label>
                                            <div className="mt-2 flex">
                                                <select
                                                    id="location"
                                                    name="location"
                                                    value={month}
                                                    onChange={(e) => setMonth(e.target.value)}
                                                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                >
                                                    <option value={0}>01</option>
                                                    <option value={1}>02</option>
                                                    <option value={2}>03</option>
                                                    <option value={3}>04</option>
                                                    <option value={4}>05</option>
                                                    <option value={5}>06</option>
                                                    <option value={6}>07</option>
                                                    <option value={7}>08</option>
                                                    <option value={8}>09</option>
                                                    <option value={9}>10</option>
                                                    <option value={10}>11</option>
                                                    <option value={11}>12</option>
                                                </select>

                                                <div style={{"padding-top": "0.7rem"}}>
                                                    &nbsp;/&nbsp;
                                                </div>

                                                <select
                                                    id="location"
                                                    name="location"
                                                    value={year}
                                                    onChange={(e) => {
                                                        setYear(e.target.value)
                                                    }}
                                                    className="mt-2 min-w-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                >
                                                    {years.map((year) => (
                                                        <option value={year}>{year}</option>
                                                    ))}
                                                </select>

                                                {/*<DatePicker*/}
                                                {/*    className="ddblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
                                                {/*    selected={loggedAt.startDate}*/}
                                                {/*    onChange={(date) => handleValueChange({startDate: date})}*/}
                                                {/*/>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                                <div className="divide-y pb-6 divide-gray-200 px-4 sm:px-6">
                                <fieldset aria-label="Plan">
                                            <div className="relative flex items-start">
                                                <div className="flex h-6 items-center">
                                                    <input
                                                        checked={'recurring' === expenseType}
                                                        onChange={() => expenseType == 'recurring' ? setExpenseType('variable') : setExpenseType('recurring')}
                                                        id={'recurring'}
                                                        name="plan"
                                                        type="checkbox"
                                                        aria-describedby={`recurring-description`}
                                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm leading-6">
                                                    <label htmlFor={'recurring'} className="font-medium text-gray-900">
                                                        Recurring
                                                    </label>{' '}
                                                    <span id={`recurring-description`} className="text-gray-500">
                These are predictable costs that remain consistent month to month, such as rent, mortgage, or car payments.
              </span>
                                                </div>
                                            </div>
                                </fieldset>
                                </div>
                            </div>
                            <hr />
                            <div className="flex flex-1 flex-col justify-between">
                                <div className="divide-gray-200 px-4 sm:px-6">
                                    <div className="mt-5 mb-3 text-base font-semibold leading-6 text-gray-900">History</div>
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        Month/Year
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Amount
                                    </th>
                                    {expense.entries.length > 1 && <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Actions
                                    </th>}

                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {expense.entries.map((entry) => (
                                    <tr key={decodeLoggedAt(entry.loggedAt)}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                            {formatDate(toDateTime(decodeLoggedAt(entry.loggedAt)), 'MM/yyyy')}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatMoney(entry.costInCents/100)}</td>
                                        <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                                            {expense.entries.length > 1 && (confirmDeleteEntryTimestamp === entry.uuid ? (
                                                <button
                                                    onClick={async () => {
                                                        await controls.removeEntry({
                                                            expenseId: expense.id,
                                                            uuid: entry.uuid,
                                                        })
                                                        setIsOpen(false)
                                                        await controls.selectExpense(null)
                                                    }}
                                                    type="button"
                                                    className={classNames(
                                                        "rounded-full px-2.5 py-1 text-xs font-semibold shadow-sm ring-1 ring-inset",
                                                        "bg-red-500 text-red-50 ring-red-300 hover:bg-red-500 "
                                                    )}
                                                >
                                                    Confirm
                                                </button>) :
                                            <button
                                                onClick={() => {
                                                    setConfirmDeleteEntryTimestamp(entry.uuid)
                                                }}
                                                type="button"
                                                className={classNames(
                                                    "rounded-full px-2.5 py-1 text-xs font-semibold shadow-sm ring-1 ring-inset",
                                                    " bg-red text-red-900 ring-red-300 hover:bg-red-50 "
                                                )}
                                            >
                                                &nbsp;Delete&nbsp;&nbsp;
                                            </button>)}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            </div>
                            </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            <button
                                type="button"
                                onClick={() => setIsOpen(false)}
                                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                Cancel
                            </button>
                            <SubmitButton
                                text={"Save"}
                                loadingText={"Saving..."}
                                onSubmit={async () => {
                                    await controls.save({expenseType, costInCents: newExpenseCost * 100, loggedAt: new Date(year, month, 14)})
                                }}
                                />
                        </div>
                    </form>
                </DialogPanel>
            </div>
        </div>
    </div>
    </Dialog>
}
