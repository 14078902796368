import {initializeApp} from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyD81pm2J4UAVso-4uH69oz-e4V6412pZls",
    authDomain: "kaizen-finance.firebaseapp.com",
    projectId: "kaizen-finance",
    storageBucket: "kaizen-finance.appspot.com",
    messagingSenderId: "716447860147",
    appId: "1:716447860147:web:ea61d17b2f2323772df7c6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db};
