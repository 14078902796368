import {Dialog, DialogPanel, DialogTitle} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {useState} from "react";
import useExpenses from "../../hooks/useExpenses";
import useUserDataStore from "../../hooks/useUserDataStore";
import {useAuth} from "../../providers/authprovider";
import DatePicker from "react-datepicker";
import {SubmitButton} from "../Button";
import CurrencyInput from "react-currency-input-field";


export const NewGoalSidebar = ({isOpen, setIsOpen, goalStore}) => {
    const [goalCustomTitle, setGoalCustomTitle] = useState('')
    const [targetAmountInCents, setTargetAmountInCents] = useState(0)
    const [currentAmountSavedInCents, setCurrentAmountSavedInCents] = useState(0)
    const [endDate, setEndDate] = useState(new Date())
    const authData = useAuth()
    const {
        userData,
    } = useUserDataStore()

    if (!userData) {
        return null
    }

    const storeGoal = async (e) => {
        e.preventDefault()

        try {
            await goalStore.addGoal({
                targetAmountInCents,
                currentAmountSavedInCents,
                deadlineAt: endDate.toISOString(),
                customTitle: goalCustomTitle,
            })
            setTargetAmountInCents(0)
            setCurrentAmountSavedInCents(0)
            setEndDate(new Date())
            setGoalCustomTitle('')
            setIsOpen(false)
        } catch (error) {
            console.error("Error adding document: ", error);
        }

        // try {
        //     await controls.addExpense({newExpenseName, newExpenseCost, loggedAt})
        //     setNewExpenseCost(0)
        //     setNewExpenseName('')
        //     setIsOpen(false)
        // } catch (error) {
        //     console.error("Error adding document: ", error);
        // }
    }

    return <Dialog open={isOpen} onClose={setIsOpen} className="relative z-10">
        <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                    <DialogPanel
                        transition
                        className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                    >
                        <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                            <div className="h-0 flex-1 overflow-y-auto">
                                <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                                    <div className="flex items-center justify-between">
                                        <DialogTitle className="text-base font-semibold leading-6 text-white">🎯 New Goal</DialogTitle>
                                        <div className="ml-3 flex h-7 items-center">
                                            <button
                                                type="button"
                                                onClick={() => setIsOpen(false)}
                                                className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                            >
                                                <span className="absolute -inset-2.5" />
                                                <span className="sr-only">Close panel</span>
                                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mt-1">
                                        <p className="text-sm text-indigo-300">
                                            Get started by filling in the information below to create your new expense.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-1 flex-col justify-between">
                                    <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                        <div className="space-y-6 pb-5 pt-6">
                                            <div>
                                                <label htmlFor="project-name" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Goal Title
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="project-name"
                                                        name="project-name"
                                                        type="text"
                                                        value={goalCustomTitle}
                                                        onChange={(e) => setGoalCustomTitle(e.target.value)}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                    How much you want to save?
                                                </label>
                                                <div className="mt-2">
                                                    <CurrencyInput
                                                        id="input-example"
                                                        name="input-name"
                                                        placeholder="Please enter a amount"
                                                        defaultValue={0}
                                                        value={targetAmountInCents/100}
                                                        className="ddblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        decimalsLimit={2}
                                                        onValueChange={(value, name, values) => setTargetAmountInCents(parseFloat(value || 0) * 100)}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                    End Date
                                                </label>
                                                <div className="mt-2">
                                                    <DatePicker
                                                        className="ddblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        selected={endDate}
                                                        onChange={(date) => setEndDate(date)}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                    What is your current amount saved for this goal?
                                                </label>
                                                <div className="mt-2">
                                                    <CurrencyInput
                                                        id="input-example"
                                                        name="input-name"
                                                        placeholder="Please enter a amount"
                                                        defaultValue={0}
                                                        value={currentAmountSavedInCents/100}
                                                        className="ddblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        decimalsLimit={2}
                                                        onValueChange={(value, name, values) => setCurrentAmountSavedInCents(parseFloat(value || 0) * 100)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                <button
                                    type="button"
                                    onClick={() => setIsOpen(false)}
                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    Cancel
                                </button>
                                <SubmitButton
                                    text="Save"
                                    loadingText={"Saving..."}
                                    onSubmit={async (e) => {
                                        await storeGoal(e)
                                    }}
                                />
                            </div>
                        </form>
                    </DialogPanel>
                </div>
            </div>
        </div>
    </Dialog>
}
