import {decodeLoggedAt, formatMoney, toDateTime} from "../../utils/utils";
import {formatDate, isAfter, isBefore} from "date-fns";


export default function Goal({
        type,
        goal,
    selectGoal,
        customTitle,
        createdAt,
        targetAmountInCents,
        deadlineAt,
        investmentPlanEntries,
                                 currentAmountSavedInCents,
    }) {

    const normalisedInvestmentPlanEntries = (investmentPlanEntries || []).map(entry => ({
        ...entry,
        loggedAt: toDateTime(decodeLoggedAt(entry.loggedAt))
    }));

    console.log('Goal:createdAt', new Date(createdAt))
    console.log('Goal:deadlineAt', new Date(deadlineAt))
    const filterNormalisedInvestmentPlanEntries = normalisedInvestmentPlanEntries.filter(entry => {
        // check date is after createdAt via date-fns
        console.log('Entry:loggedAt', new Date(entry.loggedAt))
        return isAfter(entry.loggedAt, new Date(createdAt)) && isBefore(entry.loggedAt, new Date(deadlineAt))
    })
    console.log('Goal:filterNormalisedInvestmentPlanEntries', filterNormalisedInvestmentPlanEntries)

    const totalInvestedAmountInCents = filterNormalisedInvestmentPlanEntries.reduce(
        (acc, entry) => {return acc + entry.costInCents}, 0) + (currentAmountSavedInCents || 0)
    console.log('Goal:totalInvestedAmountInCents', totalInvestedAmountInCents)
    console.log('Goal:targetAmountInCents', targetAmountInCents)
    const percentage = totalInvestedAmountInCents / targetAmountInCents * 100

    const formatDeadlineToDate = formatDate(deadlineAt, 'MMM dd, yyyy')

    var mainTitle = customTitle || "";
    var secondaryTitle = "";
    if (mainTitle) {
        secondaryTitle = `Invest ${formatMoney(targetAmountInCents/100)} by ${formatDeadlineToDate}`
    } else {
        mainTitle = `Invest ${formatMoney(targetAmountInCents/100)} by ${formatDeadlineToDate}`
    }

    return (
        <div className={"cursor-pointer"} onClick={() => selectGoal(goal)}>
            <h4 className="sr-only">Status</h4>
            <p className="text-lg font-medium text-gray-900">
                {mainTitle}
                <span className={"text-sm text-gray-500 float-right"}>created on {formatDate(createdAt, 'MMM dd, yyyy')}</span>
            </p>
            {secondaryTitle ? <p className="text-sm font-medium text-gray-500">{secondaryTitle}</p> : null}
            {/*<p>Insights</p>*/}
            {/*<p>Chart</p>*/}
            {/*<p>Created by</p>*/}
            {/*<p>All investments that contribute to the goal</p>*/}
            <div aria-hidden="true" className="mt-6">
                <div className="mt-1 overflow-hidden rounded-full bg-gray-200">
                    {percentage == '100'
                        ? <div style={{ width: percentage + '%' }} className="h-2 rounded-full bg-green-600" />
                        : <div style={{ width: percentage + '%' }} className="h-2 rounded-full bg-indigo-600" />}
                </div>
                <div className="mt-3 grid grid-cols-3 text-sm font-medium text-gray-600">
                    <div className="text-indigo-600">{formatMoney(totalInvestedAmountInCents/100)}</div>
                    {/*<div className="text-center text-indigo-600">Migrating database</div>*/}
                    <div className="text-center">{formatMoney(targetAmountInCents/2/100)}</div>
                    <div className="text-right">{formatMoney(targetAmountInCents/100)}</div>
                </div>
            </div>
        </div>
    )
}
