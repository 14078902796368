import { v4 as uuidv4 } from 'uuid';
import useLocalState from "./useLocalState";

export const useDemoPortfolioNavUpdates = (accountId) => {
    const [navUpdates, setNavUpdates] = useLocalState('portfolioNavUpdates', [{"id":"0b27c8f6-4a9f-4417-bc6d-2b3c3ba1ae25","amountInCents":19200000,"loggedAt":"2024-10-19T12:20:19.342Z","isInvestment":false},{"id":"11389107-fb42-45ab-be7d-4710dcadd730","amountInCents":19200000,"loggedAt":"2024-10-19T12:20:19.342Z","isInvestment":false},{"id":"ec4d00b6-25b0-4990-82fd-4612d52e7a6c","amountInCents":19200000,"loggedAt":"2024-10-19T12:20:19.342Z","isInvestment":false},{"id":"d7e29603-c5a5-4555-acdf-cbb811429210","amountInCents":1930000,"loggedAt":"2024-10-19T12:20:19.342Z","isInvestment":false},{"id":"e67a0b10-3d6a-4cb0-b15e-b545ba57d709","amountInCents":19300000,"loggedAt":"2024-10-19T12:20:19.342Z","isInvestment":false},{"id":"49796c9a-7c09-46b4-9365-8e6f87a28419","amountInCents":19300000,"loggedAt":"2024-10-19T12:20:19.342Z","isInvestment":false},{"id":"964c3624-a3e4-4aff-a8a7-298fb5f8a974","amountInCents":19300000,"loggedAt":"2024-10-19T12:20:19.342Z","isInvestment":false},{"id":"1adfb7ec-6680-4aad-a1e8-0d3242c8e87d","amountInCents":19300000,"loggedAt":"2024-10-19T12:20:19.342Z","isInvestment":false},{"id":"bdeac326-43c0-4f30-9a38-5bb894325220","amountInCents":19299900,"loggedAt":"2024-10-19T12:20:19.342Z","isInvestment":false},{"id":"55b5f64c-c2cb-41d2-ac2e-09e031fde202","amountInCents":19300000,"loggedAt":"2024-10-19T12:20:19.342Z","isInvestment":false},{"id":"0ef03468-afbf-41aa-a702-858114d8a6ae","amountInCents":4200,"loggedAt":"2024-10-19T10:11:20.713Z","isInvestment":false},{"id":"62c3bee5-728b-47d6-877a-3d23ffa47ff2","amountInCents":4200000,"loggedAt":"2024-10-19T10:11:20.713Z","isInvestment":false},{"id":"bfed6f0f-f87c-4b2d-8957-9a98ba2089ed","amountInCents":4200000,"loggedAt":"2024-10-19T10:11:20.713Z","isInvestment":false},{"id":"93be6570-fc4c-44fd-a97c-ea315d7edcc9","amountInCents":4200000,"loggedAt":"2024-10-19T10:11:20.713Z","isInvestment":false}]);

    const addPortfolioNav = async ({amountInCents, loggedAt, isInvestment}) => {
        setNavUpdates([...navUpdates, {
            id: uuidv4(),
            userId: accountId,
            amountInCents: amountInCents,
            loggedAt: new Date(loggedAt),
            isInvestment: isInvestment || false
        }])
    }

    const removePortfolioNav = async (id) => {
        setNavUpdates(navUpdates.filter(navUpdate => navUpdate.id !== id))
    }

    return { navUpdates, addPortfolioNav, removePortfolioNav };
};

