/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
'use client'

import { useState } from 'react'
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
} from '@headlessui/react'
import {
    Bars3Icon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import {useAuth} from "../providers/authprovider";
import {usePortfolioNavUpdates} from "../hooks/usePortfolioNavUpdates";
import useUserDataStore from "../hooks/useUserDataStore";
import useExpenses from "../hooks/useExpenses";
import {signOut} from "firebase/auth";
import {auth} from "../firebaseInit";
import {
    dbExpensesToSteps,
    formatMoney,
    getLastEntry,
    getMonthlyPassiveIncomeFromNavUpdate
} from "../utils/utils";
import Expenses from "../components/Expenses";
import ExpenseDeleteConfirmation from "../components/ExpenseDeleteConfirmation";
import {ExepenseUpdateSidebar} from "../components/ExepenseUpdateSidebar";
import {NewExpenseSidebar} from "../components/NewExpenseSidebar";
import {UpdateInvestmentAmountSidebar} from "../components/UpdateInvestmentAmountSidebar";
import {InvestmentPlanSidebar} from "../components/InvestementPlanSidebar";
import {InvestmentPlanAmountSidebar} from "../components/Sidebars/InvestmentPlanAmountSidebar";
import GuidePopup from "../components/GuidePopup";
import {
    BanknotesIcon,
    BuildingLibraryIcon,
    CreditCardIcon,
    HomeIcon,
    PencilIcon,
    PencilSquareIcon
} from "@heroicons/react/16/solid";
import {PassiveIncomeSidebar} from "../components/PassiveIncomeSidebar";
import {useDemoPassiveIncomeEntries} from "../hooks/useDemoPassiveIncomeEntries";
import useDemoUserDataStore from "../hooks/useDemoUserDataStore";
import {useDemoPortfolioNavUpdates} from "../hooks/useDemoPortfolioNavUpdates";
import useDemoExpenses from "../hooks/useDemoExpenses";
import {Layout} from "../components/Layout";
import IntroPopup from "../components/IntroPopup";

const DEFAULT_YIELD = 4

function Stats(props) {
    return <dl className="">
        <div
            id="portfolioValue"
            className="fk-pencil-block cursor-pointer mb-5 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6"
            onClick={props.onClick}
        >
            <dt>
                <div className="absolute rounded-md bg-indigo-500 p-3">
                    <BuildingLibraryIcon aria-hidden="true" className="h-6 w-6 text-white"/>
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">Portfolio Value</p>
                <div className=" invisible absolute rounded-md p-3 right-0 top-0 hover:visible">
                    <PencilSquareIcon aria-hidden="true" className="fk-pencil h-6 w-6 text-indigo-500"/>
                </div>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">
                    {formatMoney(props.amount)}
                </p>
            </dd>
        </div>
        <div
            id="monthlyWithdrawalNumber"
            className="cursor-pointer mb-5 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6"
            onClick={() => props.setIsPassiveIncomeSidebarOpen(true)}
        >
            <dt>
                <div className="absolute rounded-md bg-indigo-500 p-3">
                    <BanknotesIcon aria-hidden="true" className="h-6 w-6 text-white"/>
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">Monthly Withdrawal Number</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">
                    {formatMoney(props.amount1)}
                </p>
            </dd>
        </div>
        <div
            id="monthlyInvestmentPlan"
            className="cursor-pointer mb-5 relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6"
            onClick={props.onClick1}
        >
            <dt>
                <div className="absolute rounded-md bg-indigo-500 p-3">
                    <CreditCardIcon aria-hidden="true" className="h-6 w-6 text-white"/>
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">Monthly investment plan</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">
                    {props.userData.investmentPlanAmount ? formatMoney(props.userData.investmentPlanAmount) : formatMoney(0)}
                </p>
            </dd>
        </div>

        <p className="mt-5 text-sm text-gray-900 bg-indigo-50 rounded-sm px-3 py-3 font-medium mb-10">
            <div className={"text-lg font-bold"}>
                🪴 This is demo mode
            </div>
            <div className="">
                <div className={"pl-5 pt-2"}>Your data is <b>temporarily stored</b> in your browser, and some features are limited.
                    <br/>
                    <br/>

                    To store your data reliably and unlock all features, including <b>goal tracking</b>, settings and be able to use the app across devices, <b>create a free account</b>.
                    <br/>
                    <br/>
                </div>

                <button
                    onClick={() => window.location.href = "/signup"}
                    type="button"
                    className="w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Sign up for free
                </button>
                {/*Sign up for a trial to access all features — no credit card required.*/}
                {/*<br/>*/}
                {/*The trial lasts for 7 days and will be automatically extended until the project exits beta.*/}

                {/*<br/>*/}

            </div>
        </p>
    </dl>;
}

export function Demo() {

    const {
        userData,
        updateInvestmentPlanAmount,
        addInvestmentPlanEntry,
        removeInvestmentPlanEntry,
        syncUserData
    } = useDemoUserDataStore()
    const portfolioNavStore = useDemoPortfolioNavUpdates()

    const [isPassiveIncomeSidebarOpen, setIsPassiveIncomeSidebarOpen] = useState(false)

    const [isNewExpenseSidebarOpen, setIsNewExpenseSidebarOpen] = useState(false)
    const [isUpdateExpenseSidebarOpen, setIsUpdateExpenseSidebarOpen] = useState(false)
    const [isDeleteExpenseSidebarOpen, setIsDeleteExpenseSidebarOpen] = useState(false)
    const [isUpdateInvestementAmountSidebarOpen, setIsInvestementAmountSidebarOpen] = useState(false)
    const [isInvestmentPlanSidebarOpen, setIsInvestmentPlanSidebarOpen] = useState(false)
    const [isInvestmentPlanAmountSidebarOpen, setIsInvestmentPlanAmountSidebarOpen] = useState(false)
    const [isGuidePopupOpen, setIsGuidePopupOpen] = useState(false)
    // const updateExpenseSidebarData = useUpdateExpenseSidebar()
    const expenseStore = useDemoExpenses()
    const passiveIncomeStore = useDemoPassiveIncomeEntries()
    const [cumulative, setCumulative] = useState(true)

    const {expenses, currentExpense, controls} = expenseStore


    const signOutHandler = async () => {
        await signOut(auth);
    }

    const liquidPassiveIncome = portfolioNavStore && userData ? getMonthlyPassiveIncomeFromNavUpdate(portfolioNavStore.navUpdates, userData.yield || DEFAULT_YIELD) : 0
    const monthlyPassiveIncome = liquidPassiveIncome + (passiveIncomeStore.passiveIncomeEntriesTotal / 100)

    const user = {
        name: 'Demo User',
        email: 'example@example.com',
        imageUrl: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    }

    const lastEntry = getLastEntry(portfolioNavStore.navUpdates)
    const lastEntryAmount = (lastEntry?.amountInCents || 0) / 100

    const updateExpense = (id) => {
        const expense = expenses.find((e) => e.id == id)
        console.log('App:updateExpense:', expense)
        controls.selectExpense(expense)
        setIsUpdateExpenseSidebarOpen(true)
    }


    let restOfIncome = monthlyPassiveIncome
    const expensesAsSteps = expenses.length ? dbExpensesToSteps(expenses, restOfIncome, cumulative) : []
    // Total monthly expenses
    // const total = expensesTotal(data)

    return (
        <Layout pageSlug="dashboard" mode={"demo"}
                aside={<Stats onClick={() => setIsInvestementAmountSidebarOpen(true)} amount={lastEntryAmount}
                              amount1={monthlyPassiveIncome} onClick1={() => setIsInvestmentPlanAmountSidebarOpen(true)}
                              setIsPassiveIncomeSidebarOpen={setIsPassiveIncomeSidebarOpen}
                              userData={userData}/>}>
            <div className="lg:hidden px-4 pt-6 border-b-2">
                <Stats onClick={() => setIsInvestementAmountSidebarOpen(true)} amount={lastEntryAmount}
                       amount1={monthlyPassiveIncome} onClick1={() => setIsInvestmentPlanAmountSidebarOpen(true)}
                       setIsPassiveIncomeSidebarOpen={setIsPassiveIncomeSidebarOpen}
                       userData={userData}/>
            </div>
            <div className="px-4 py-6 sm:px-6 lg:px-8 lg:py-6">
                <Expenses mode={"demo"}
                    monthlyPassiveIncome={monthlyPassiveIncome} expenseStore={expenseStore}
                          openExpenseForm={setIsNewExpenseSidebarOpen}
                          setIsGuidePopupOpen={setIsGuidePopupOpen}
                          expensesAsSteps={expensesAsSteps}
                          cumulative={cumulative}
                          setCumulative={setCumulative}
                          openDeleteExpenseDialog={(expense) => {
                              controls.selectExpense(expense)
                              setIsDeleteExpenseSidebarOpen(true)
                          }} expenses={expenses} topExpense={() => {
                }}
                          updateExpense={updateExpense}
                          setIsInvestmentPlanSidebarOpen={setIsInvestmentPlanSidebarOpen}
                />
            </div>

            <ExpenseDeleteConfirmation expense={currentExpense} controls={controls}
                                       isOpen={isDeleteExpenseSidebarOpen}
                                       setIsOpen={setIsDeleteExpenseSidebarOpen}/>
            {currentExpense && <ExepenseUpdateSidebar expense={currentExpense} controls={controls}
                                                      isOpen={isUpdateExpenseSidebarOpen}
                                                      setIsOpen={setIsUpdateExpenseSidebarOpen}/>}
            <NewExpenseSidebar controls={controls} isOpen={isNewExpenseSidebarOpen}
                               setIsOpen={setIsNewExpenseSidebarOpen}/>
            <UpdateInvestmentAmountSidebar
                {...{
                    portfolioNavStore,
                    isOpen: isUpdateInvestementAmountSidebarOpen,
                    setIsOpen: setIsInvestementAmountSidebarOpen,
                }}
            />
            {userData && <PassiveIncomeSidebar
                {...{
                    userData,
                    isOpen: isPassiveIncomeSidebarOpen,
                    setIsOpen: setIsPassiveIncomeSidebarOpen,
                    liquidPassiveIncome,
                    monthlyPassiveIncome,
                    portfolioNav: lastEntryAmount,
                    passiveIncomeEntries: passiveIncomeStore.passiveIncomeEntries,
                    addPassiveIncomeEntry: passiveIncomeStore.addPassiveIncomeEntry,
                    removePassiveIncomeEntry: passiveIncomeStore.removePassiveIncomeEntry,
                }}
            />}
            {userData && <InvestmentPlanSidebar
                {...{
                    userData,
                    syncUserData,
                    addInvestmentPlanEntry,
                    removeInvestmentPlanEntry,
                    isOpen: isInvestmentPlanSidebarOpen,
                    setIsOpen: setIsInvestmentPlanSidebarOpen,
                    portfolioNavStore
                }} />}
            {userData && <InvestmentPlanAmountSidebar
                {...{
                    amount: userData.investmentPlanAmount,
                    updateInvestmentPlanAmount: updateInvestmentPlanAmount,
                    isOpen: isInvestmentPlanAmountSidebarOpen,
                    setIsOpen: setIsInvestmentPlanAmountSidebarOpen,
                }} />}
            {userData && userData.investmentPlanAmount && <GuidePopup
                cumulative={cumulative}
                expensesAsSteps={expensesAsSteps}
                expenseStore={expenseStore}
                portfolioNavStore={portfolioNavStore}
                monthlyPassiveIncome={monthlyPassiveIncome}
                userData={userData}
                isOpen={isGuidePopupOpen} setIsOpen={setIsGuidePopupOpen}/>}
            {/*<IntroPopup />*/}
        </Layout>
    )
}
