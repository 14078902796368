import * as React from 'react';

export const SubmitButton = ({text, loadingText, onSubmit}) => {
    const [isSubmitting, setIsSubmitting] = React.useState(false)

    return <button
        type="submit"
        disabled={isSubmitting}
        onClick={async (e) => {
            e.preventDefault()

            setIsSubmitting(true)
            await onSubmit(e)

            setIsSubmitting(false)
        }}
        className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
        {isSubmitting ? <svg
            class="animate-spin h-5 w-5 mr-2 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
        >
            <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
            ></circle>
            <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
            ></path>
        </svg> : null}
        {isSubmitting ? loadingText || text : text}
    </button>
}
