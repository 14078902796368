import {Dialog, DialogPanel, DialogTitle} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {useEffect, useState} from "react";

import "react-datepicker/dist/react-datepicker.css";
import {SubmitButton} from "../Button";

export const InvestmentPlanAmountSidebar = ({amount, isOpen, setIsOpen, updateInvestmentPlanAmount}) => {
    const [newAmount, setNewAmount] = useState(amount) // amount in major currency

    return <Dialog open={isOpen} onClose={setIsOpen} className="relative z-40">
        <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                    <DialogPanel
                        transition
                        className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                    >
                        <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                            <div className="h-0 flex-1 overflow-y-auto">
                                <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                                    <div className="flex items-center justify-between">
                                        <DialogTitle className="text-base font-semibold leading-6 text-white">🎯 Set Monthly Investment Amount</DialogTitle>
                                        <div className="ml-3 flex h-7 items-center">
                                            <button
                                                type="button"
                                                onClick={() => setIsOpen(false)}
                                                className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                            >
                                                <span className="absolute -inset-2.5" />
                                                <span className="sr-only">Close panel</span>
                                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mt-1">
                                        <p className="text-sm text-indigo-300">
                                            What amount do you plan to invest monthly?
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-1 flex-col justify-between">
                                    <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                        <div className="space-y-6 pb-5 pt-6">
                                            <div>
                                                <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Amount that you plan to invest monthly
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="value"
                                                        name="value"
                                                        type="number"
                                                        value={newAmount}
                                                        onChange={(e) => setNewAmount(e.target.value)}
                                                        rows={4}
                                                        className="ddblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                <button
                                    type="button"
                                    onClick={() => setIsOpen(false)}
                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    Cancel
                                </button>
                                <SubmitButton
                                    text={"Update"}
                                    loadingText={"Updating..."}
                                    onSubmit={async () => {

                                        await updateInvestmentPlanAmount(newAmount)
                                        setIsOpen(false)
                                    }} />
                            </div>
                        </form>
                    </DialogPanel>
                </div>
            </div>
        </div>
    </Dialog>
}
