
export const ExpenseButtons = ({step, showGuide, openDeleteExpenseDialog, cumulative}) => {

    return <div className={"ml-auto space-x-1 li-buttons" }>
        {showGuide && <button
            onClick={(e) => {
                e.stopPropagation()

                showGuide()
            }}
            type="button"
            className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-indigo-900 shadow-sm ring-1 ring-inset ring-indigo-300 hover:bg-indigo-50"
        >
            Insights
        </button>}

        <button
            onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()

                openDeleteExpenseDialog(step)
            }}
            type="button"
            className="rounded-full bg-red px-2.5 py-1 text-xs font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
        >
            Delete
        </button>
    </div>
}
