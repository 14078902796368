import {useAuth} from "../providers/authprovider";
import {useCallback, useEffect, useState} from "react";
import {arrayUnion, doc, getDoc, setDoc, updateDoc} from "firebase/firestore";
import {db} from "../firebaseInit";
import { v4 as uuidv4 } from 'uuid';
import {getLastEntry} from "../utils/utils";

const useUserDataStore = () => {
    const user = useAuth();
    const [lastUpdatedAt, setLastUpdatedAt] = useState(null);
    const [userData, setUserData] = useState(null);

    const syncUserData = async (data) => {
        await setDoc(doc(db, "user_settings", user.currentUser.uid), {
            ...data
        }, { merge: true })
        setLastUpdatedAt(new Date());
    };

    const fetchUserData = useCallback(async () => {
        if (!user || !user.currentUser) return;

        try {

            const userRef = doc(db, "users", user.currentUser.uid);
            const userSnap = await getDoc(userRef);

            if (!userSnap.exists()) {
                console.error("No such user!", user.currentUser.uid);
                return;
            }

            const docRef = doc(db, "user_settings", userSnap.data().account)
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data());
                setUserData({
                    ...docSnap.data(),
                    accountId: userSnap.data().account
                });
            } else {
                console.error("No such document!");
            }

            window.GLOBAL_CURRENCY = docSnap.data().currency;

        } catch (error) {
            console.error("Error fetching document:", error);
        }
    }, [user, lastUpdatedAt]);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData, lastUpdatedAt]);

    const updateInvestmentPlanAmount = async (amountInMajorCurrency) => {
            const docRef = doc(db, "user_settings", userData.accountId);
            console.log('useUserData:updateInvestmentPlanAmount:', docRef, amountInMajorCurrency)
            await updateDoc(docRef, {
                investmentPlanAmount: amountInMajorCurrency,
            });
            setLastUpdatedAt(new Date());
    }

    const addInvestmentPlanEntry = async ({costInCents, loggedAt, portfolioNavStore}) => {

            const docRef = doc(db, "user_settings", userData.accountId);
            console.log('useUserData:addInvestementPlanLogEntry:', docRef, costInCents)


            await updateDoc(docRef, {
                investmentPlanEntries: arrayUnion({
                    uuid: uuidv4(),
                    costInCents,
                    loggedAt: new Date(loggedAt)
                }),
            });

            const lastNav = getLastEntry(portfolioNavStore.navUpdates)?.amountInCents || 0
            console.log('addInvestmentPlanEntry:lastNav', lastNav, portfolioNavStore)

            await portfolioNavStore.addPortfolioNav({
                amountInCents: lastNav + costInCents,
                loggedAt: new Date(loggedAt),
                isInvestment: true
            })

            setLastUpdatedAt(new Date());
    }

    const removeInvestmentPlanEntry = async ({uuid, costInCents, portfolioNavStore}) => {
        console.log('removeInvestmentPlanEntry:userData', userData)

            const docRef = doc(db, "user_settings", userData.accountId);
            console.log('useUserData:removeInvestmentPlanEntry:', docRef, uuid)

            const lastEntry = getLastEntry(userData.investmentPlanEntries)

            const newEntries = userData.investmentPlanEntries.filter(entry => entry.uuid !== uuid)

            await updateDoc(docRef, {
                investmentPlanEntries: newEntries,
                investedAmountEntries: arrayUnion({
                    uuid: uuidv4(),
                    costInCents: lastEntry.costInCents - costInCents,
                    loggedAt: new Date()
                })
            });

            // Let's not drop it for now.
            // const lastNav = getLastEntry(portfolioNavStore.navUpdates)?.amountInCents || 0
            // console.log('addInvestmentPlanEntry:lastNav', lastNav, portfolioNavStore)

            // await portfolioNavStore.addPortfolioNav({
            //     amountInCents: lastNav - costInCents,
            //     loggedAt: new Date(),
            //     isInvestment: false
            // })

            setLastUpdatedAt(Date.now())
    }

    return {userData, syncUserData, addInvestmentPlanEntry, removeInvestmentPlanEntry, updateInvestmentPlanAmount};
};

export default useUserDataStore;
