import {useState} from "react";
import {useAuth} from "../../providers/authprovider";
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth, db} from "../../firebaseInit";
import {Navigate} from "react-router-dom";
import {addDoc, collection, doc, getDoc, setDoc} from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';


export const LoginStepPage = ({passiveIncome, investments, biggestExpense, smallestExpense}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    // const authData = useAuth();
    const [onboardingFinished, setOnboardingFinished] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();

            const userCreds = await signInWithEmailAndPassword(auth, email, password);
            // window.location.href = "/app";

            console.log('User creds: ', userCreds)

            // Check if user already onboarded
            const userRef = doc(db, "users", userCreds.user.uid);
            const userSnap = await getDoc(userRef);
            if (userSnap.exists()) {
                throw new Error(`Such user already onboarded UID=${userCreds.user.uid}`);
            }

            // Store users object
            const usersRef = collection(db, "users");
            await setDoc(doc(usersRef, userCreds.user.uid),{
                account: userCreds.user.uid,
                email: userCreds.user.email,
            });

            // Store user settings object
            const userSettingsRef = collection(db, "user_settings");
            await setDoc(doc(userSettingsRef, userCreds.user.uid),{
                yield: 4, // DEFAULT_YIELD
            });

            const passiveIncomeRef = collection(db, "passiveIncomeEntries");
            await setDoc(doc(passiveIncomeRef, userCreds.user.uid), {
                userId: userCreds.user.uid,
                amountInCents: passiveIncome.amount * 100,
                loggedAt: new Date(),
                title: passiveIncome.name,
            });

            const portfolioNavRef = collection(db, "portfolioNavUpdates");
            await setDoc(doc(portfolioNavRef, userCreds.user.uid), {
                userId: userCreds.user.uid,
                amountInCents: investments.amount * 100,
                loggedAt: new Date(),
                isInvestment: false
            });

            const expensesRef = collection(db, "expenses");
            const currentDate = new Date();
            const loggedAt = new Date(currentDate.getFullYear(), currentDate.getMonth(), 14, 0, 0, 0, 0);
            await setDoc(doc(expensesRef, userCreds.user.uid), {
                userId: userCreds.user.uid,
                name: biggestExpense.name,
                entries: [{
                    uuid: uuidv4(),
                    costInCents: biggestExpense.amount * 100,
                    loggedAt: loggedAt
                }]
            });

            // Store smallest expense additionally
            await addDoc(collection(db, "expenses"), {
                userId: userCreds.user.uid,
                name: smallestExpense.name,
                entries: [{
                    uuid: uuidv4(),
                    costInCents: smallestExpense.amount * 100,
                    loggedAt: loggedAt
                }]
            });

            setOnboardingFinished(true);

    };
    //
    // if (authData.isLoading){
    //     return null
    // }
    if (onboardingFinished) {
        return <Navigate to="/app" replace />
    }

        return <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-10 lg:px-8">

            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    alt="Your Company"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    className="mx-auto h-10 w-auto"
                />

                <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Setup your account
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form action="#" method="POST" className="space-y-6" onSubmit={handleLogin}>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Email address
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                autoComplete="email"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Password
                            </label>
                            <div className="text-sm">
                                {/*<a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">*/}
                                {/*    Forgot password?*/}
                                {/*</a>*/}
                            </div>
                        </div>
                        <div className="mt-2">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                autoComplete="current-password"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Sign up
                        </button>
                    </div>
                </form>

            </div>
        </div>
}
