import {Layout} from "../components/Layout";
import useUserDataStore from "../hooks/useUserDataStore";
import {useEffect, useState} from "react";
import {SubmitButton} from "../components/Button";
import {CURRENCIES} from "../utils/utils";

export const Settings = ({mode}) => {
    const {
        userData,
        syncUserData,
    } = useUserDataStore()

    const [dirtyUserData, setDirtyUserData] = useState({})

    useEffect(() => {
        if (userData) {
            setDirtyUserData(userData)
        }
    }, [userData])

    if (!userData) {
        return null
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        await syncUserData({
            currency: dirtyUserData.currency || "USD",
            yield: dirtyUserData.yield,
        })
    }

    return (
        <Layout pageSlug="settings" mode={mode}>
            <div className="px-10 py-10">
            <div className="space-y-10 divide-y divide-gray-900/10">
                <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
                    <div className="px-4 sm:px-0">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Settings</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Manage your account settings.
                        </p>

                    </div>

                    <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
                        // onSubmit={onSubmit}
                    >
                        <div className="px-4 py-6 sm:p-8">
                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-4">
                                    <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                                        Your withdrawal rate (annually, in %)
                                    </label>
                                    <div className="mt-2">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm"></span>
                                            <input
                                                id="website"
                                                name="website"
                                                value={dirtyUserData.yield}
                                                onChange={(e) => {
                                                    setDirtyUserData({ ...dirtyUserData, yield: e.target.value })
                                                }}
                                                type="number"
                                                placeholder="4"
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                        {/*<p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about yourself.</p>*/}
                                    </div>
                                </div>
                            </div>
                            </div>

                        <div className="px-4 py-6 sm:p-8">
                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-4">
                                    <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                                        Currency
                                    </label>
                                    <div className="mt-2">

                                            <select
                                                id="location"
                                                name="location"
                                                value={dirtyUserData.currency || "USD"}
                                                onChange={(e) => { setDirtyUserData({ ...dirtyUserData, currency: e.target.value }) }}
                                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            >
                                                {Object.keys(CURRENCIES).map((currency) => (
                                                    <option key={currency} value={currency}>{currency}</option>
                                                ))}
                                            </select>
                                        {/*<p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about yourself.</p>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                                Cancel
                            </button>
                            <SubmitButton
                                text="Submit"
                                loadingText={"Submitting..."}
                                onSubmit={onSubmit}
                            />
                        </div>
                    </form>
                </div>
            </div>
            </div>
        </Layout>
    )
}
