import {Layout} from "../components/Layout";

export const DemoGoals = () => {
    return (
        <Layout pageSlug="goals" mode={"demo"}>
            <div className="px-10 py-10">
                <div className="space-y-10 divide-y divide-gray-900/10">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
                        <div className="px-4 sm:px-0">
                            <p className="mt-5 text-sm text-gray-900 bg-indigo-50 rounded-sm px-3 py-3 font-medium mb-10">
                                <div className={"text-lg font-bold"}>
                                    💡 Goals are disabled in demo mode
                                </div>
                                <div className="">
                                    <div className={"pl-5 pt-2"}>Your data is <b>temporarily stored</b> in your browser, and some features are limited.
                                        <br/>
                                        <br/>

                                        To store your data reliably and unlock all features, including <b>goal tracking</b>, settings and be able to use the app across devices, <b>create a free account</b>.
                                        <br/>
                                        <br/>
                                    </div>

                                    <button
                                        onClick={() => window.location.href = "/signup"}
                                        type="button"
                                        className="w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Sign up for free
                                    </button>
                                    {/*Sign up for a trial to access all features — no credit card required.*/}
                                    {/*<br/>*/}
                                    {/*The trial lasts for 7 days and will be automatically extended until the project exits beta.*/}

                                    {/*<br/>*/}
                                </div>
                            </p>
                        </div>

                        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                            <img src={"/goals.png"} alt={"Goals"} className={"w-full"}/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
