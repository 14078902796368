import {CheckIcon, PhotoIcon, UserCircleIcon} from "@heroicons/react/16/solid";
import {Dialog, DialogBackdrop, DialogPanel, DialogTitle} from "@headlessui/react";
import {useState} from "react";
import {ExpensesStepPage} from "../components/Guide/ExpensesStepPage";
import {InvestmentsStepPage} from "../components/Guide/InvestementsStepPage";
import {IntroStepPage} from "../components/Guide/IntroStepPage";
import useLocalState from "../hooks/useLocalState";
import {SummaryStepPage} from "../components/Guide/SummaryStepPage";
import {LoginStepPage} from "../components/Guide/LoginStepPage";

const steps = [
    { id: 0, name: 'Intro', description: 'Vitae sed mi luctus laoreet.', href: '#', status: 'complete' },
    { id: 1, name: 'Log your expenses', description: 'Log your expenses', href: '#', status: 'current' },
    { id: 2, name: 'Investments', description: 'Investments', href: '#', status: 'upcoming' },
    { id: 3, name: 'Summary', description: 'Summary', href: '#', status: 'upcoming' },
]

export default function Devider() {
    return (
        <div className="relative">
            <div aria-hidden="true" className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-500"></span>
            </div>
        </div>
    )
}



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function Tabs({stepIndex, setStepIndex}) {

    const getStepId = (stepIndex) => {
        return steps[stepIndex].id + 1
    }

    return (
        <nav aria-label="Progress">
            <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
                {steps.map((step, i) => (
                    <li key={step.name} className="md:flex-1">
                        {stepIndex > i ? (
                            <a
                                href={step.href}
                                onClick={() => setStepIndex(i)}
                                className="group flex flex-col border-l-4 border-indigo-600 py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                            >
                                <span className="text-sm font-medium text-indigo-600 group-hover:text-indigo-800">{getStepId(step.id)}</span>
                                <span className="text-sm font-medium">{step.name}</span>
                            </a>
                        ) : i === stepIndex ? (
                            <a
                                href={step.href}
                                onClick={() => setStepIndex(i)}
                                aria-current="step"
                                className="flex flex-col border-l-4 border-indigo-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                            >
                                <span className="text-sm font-medium text-indigo-600">{getStepId(step.id)}</span>
                                <span className="text-sm font-medium">{step.name}</span>
                            </a>
                        ) : (
                            <a
                                href={step.href}
                                onClick={() => setStepIndex(i)}
                                className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                            >
                                <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{getStepId(step.id)}</span>
                                <span className="text-sm font-medium">{step.name}</span>
                            </a>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    )
}

export const StepWrapper = ({stepIndex, children, setStepIndex}) => {

    if (stepIndex === 4) {
        return children
    }

    return <>
    <Tabs stepIndex={stepIndex} setStepIndex={setStepIndex}/>
    <br />
    <Devider />
    <br />
        {children}
    <div className={classNames("mt-5 sm:mt-6 sm:grid-flow-row-dense sm:gap-3",
        stepIndex != 0 ? "sm:grid sm:grid-cols-2" : "",
    )}>
        {stepIndex === steps.length - 1 ?
            <button
                type="button"
                onClick={() => setStepIndex(stepIndex + 1)}
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
            >
                Sign up
            </button>
            : <button
                type="button"
                onClick={() => setStepIndex(stepIndex + 1)}
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
            >
                Next
            </button>}
        {stepIndex != 0 ? <button
            type="button"
            data-autofocus
            onClick={() => setStepIndex(stepIndex - 1)}
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
        >
            Back
        </button> : null}
    </div>
    </>
}

export function Guide() {
        const [open, setOpen] = useState(true)
        const [stepIndex, setStepIndex] = useState(0)
        const [smallestExpense, setSmallestExpense] = useLocalState('smallestExpense', {
            name: '',
            amount: 0
        })
        const [biggestExpense, setBiggestExpense] = useLocalState('biggestExpense', {
            name: '',
            amount: 0
        })
        const [investments, setInvestments] = useLocalState('investments', {
            name: '',
            amount: 0
        })
        const [passiveIncome, setPassiveIncome] = useLocalState('passiveIncome', {
            name: 'Rental income or fixed income',
            amount: 0
        })


    const showStep = (stepIndex) => {

            switch (stepIndex) {
                case 0: return <StepWrapper setStepIndex={setStepIndex} stepIndex={stepIndex}>
                    <IntroStepPage />
                </StepWrapper>
                case 1: return  <StepWrapper setStepIndex={setStepIndex} stepIndex={stepIndex}>
                    <ExpensesStepPage
                        smallestExpense={smallestExpense} setSmallestExpense={setSmallestExpense}
                        biggestExpense={biggestExpense} setBiggestExpense={setBiggestExpense}
                    />
                </StepWrapper>
                case 2: return <StepWrapper setStepIndex={setStepIndex} stepIndex={stepIndex}>
                    <InvestmentsStepPage
                        passiveIncome={passiveIncome} setPassiveIncome={setPassiveIncome}
                        investments={investments} setInvestments={setInvestments}
                />
                    </StepWrapper>
                case 3: return <StepWrapper setStepIndex={setStepIndex} stepIndex={stepIndex}>
                    <SummaryStepPage investments={investments} passiveIncome={passiveIncome} />
                    </StepWrapper>
                case 4: return <StepWrapper setStepIndex={setStepIndex} stepIndex={stepIndex}>
                    <LoginStepPage passiveIncome={passiveIncome} investments={investments} biggestExpense={biggestExpense} smallestExpense={smallestExpense} />
                </StepWrapper>
            }
        }

        return (
            <Dialog open={open} onClose={() => {}} className="relative z-10">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-indigo-50 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-2xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            {showStep(stepIndex)}
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        )
    }
