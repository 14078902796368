import {DialogTitle} from "@headlessui/react";
import {Form} from "./ExpensesStepPage";

export const IntroStepPage = ()    => {

    return <div className="mt-3 sm:mt-5">
        <DialogTitle as="h2" className="text-base font-semibold leading-6 text-gray-900">
            Track your progress towards financial independence
        </DialogTitle>

        <div className="space-y-12">

            <div className="border-b border-gray-900/10 pb-12">
                <p className="mt-1 text-sm leading-6 text-gray-600">
                    Feel lost on your financial independence journey?
                    <br/>
                    We will help you get back on track and follow your progress.
                </p>

                <br />
                {/*<p className="mt-1 text-sm leading-6 text-gray-600">*/}
                {/*    Zefire will help you:*/}
                {/*</p>*/}

                <img src="/demo2.png" alt="demo" className="" />

                {/*<ul>*/}
                {/*    <li>- Track your expenses</li>*/}
                {/*    <li>- Determine your safe withdrawal rate</li>*/}
                {/*    <li>- Track your financial independence journey</li>*/}
                {/*    <li>- Get insights how to progress during your fin journey</li>*/}
                {/*</ul>*/}
            </div>
        </div>
    </div>
}
