import {createUserWithEmailAndPassword} from "firebase/auth";
import {auth, db} from "../firebaseInit";
import {useAuth} from "../providers/authprovider";
import {useState} from "react";
import {collection, doc, getDoc, setDoc} from "firebase/firestore";
import * as React from "react";
import {WalletIcon} from "@heroicons/react/16/solid";

export default function Signup() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [submitting, setSubmitting] = useState(false);


    const handleSignup = async (e) => {
        e.preventDefault();
        if (submitting) { return }

        if (password !== passwordConfirmation) {
            alert("Passwords do not match!");
            return;
        }

        setSubmitting(true);

        try {
            const userCreds = await createUserWithEmailAndPassword(auth, email, password)

            // Check if user already onboarded
            try {
                const userRef = doc(db, "users", userCreds.user.uid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    throw new Error(`Such user already onboarded UID=${userCreds.user.uid}`);
                }

                // Store users object
                const usersRef = collection(db, "users");
                await setDoc(doc(usersRef, userCreds.user.uid), {
                    account: userCreds.user.uid,
                    email: userCreds.user.email,
                });

                // Store user settings object
                const userSettingsRef = collection(db, "user_settings");
                await setDoc(doc(userSettingsRef, userCreds.user.uid), {
                    yield: 4, // DEFAULT_YIELD
                });

                window.location.href = "/app";
            } catch (e) {
                throw new Error(`Error while signing up user: ${email}`);
                alert("Technical error while signing up user. Please reach out to us or try to sign up under another email.")
            }
        } catch (e) {
            alert(e.message)
        } finally {
            setSubmitting(false)
        }
    }

 ;

    return (
        <>
            {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <WalletIcon className="mx-auto h-12 w-auto text-indigo-600"/>
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Sign up to Zefire
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form action="#" method="POST" className="space-y-6"
                          onSubmit={handleSignup}
                    >
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    autoComplete="email"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    name="password"
                                    type="password"
                                    required
                                    autoComplete="current-password"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Confirm Password
                                </label>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    value={passwordConfirmation}
                                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                                    type="password"
                                    required
                                    autoComplete="current-password"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                disabled={submitting}
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                {submitting ? <svg
                                    class="animate-spin h-5 w-5 mr-2 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        class="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        stroke-width="4"
                                    ></circle>
                                    <path
                                        class="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                    ></path>
                                </svg> : null}
                                {submitting ? "Signing up..." :  "Sign up"}
                            </button>
                        </div>
                    </form>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Already have an account? {' '}
                        <a href="/login" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                            Sign In Here
                        </a>
                    </p>
                </div>
            </div>
        </>
    )
}
