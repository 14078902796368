import { useState, useEffect } from 'react';
import {collection, query, where, getDocs, doc, deleteDoc, updateDoc, arrayUnion, arrayRemove, addDoc} from "firebase/firestore";
import { db } from '../firebaseInit';
import { v4 as uuidv4 } from 'uuid';
import {decodeLoggedAt, toDateTime} from "../utils/utils";
import useLocalState from "./useLocalState";


const useDemoExpenses = (accountId) => {
    const [expenses, setExpenses] = useLocalState('expenses', [
        {"id":"ceef2788-3d92-4a48-8135-9b68b020dda1","name":"Netflix","expenseType":"recurring","entries":[{"uuid":"cab2e917-3b4f-4a09-b9ee-0ec17e107e99","costInCents":1500,"loggedAt":"2024-10-07T22:00:00.000Z"}]},{"id":"68165952-51b6-4ed6-bb88-173ef555e5aa","name":"Hobby","expenseType":"variable","entries":[{"uuid":"abdbba4c-1355-4d9d-af77-40ea40f721dd","costInCents":15000,"loggedAt":"2024-10-07T22:00:00.000Z"}]},{"id":"3e3b012a-f656-45a0-b6e4-4dd0401afba4","name":"Dining Out","expenseType":"variable","entries":[{"uuid":"17bccdc6-ec24-45df-9058-d74064a148d6","costInCents":16000,"loggedAt":"2024-10-13T22:00:00.000Z"}]},{"id":"21248ee7-e7dd-4694-9e66-53e8b5b3ac7b","name":"Shopping","expenseType":"variable","entries":[{"uuid":"e162c01d-e50b-4125-a3d6-b0e810e67c5d","costInCents":18000,"loggedAt":"2024-10-07T22:00:00.000Z"}]},{"id":"105ccd11-1542-4366-a537-87e9c71f5b27","name":"Rent","expenseType":"recurring","entries":[{"uuid":"59570cde-f8d4-4ee1-979d-c7d892848d19","costInCents":160000,"loggedAt":"2024-10-07T22:00:00.000Z"}]}
    ]);
    const [currentExpense, setCurrentExpense] = useLocalState('currentExpense', null)

    const selectExpense = (expense) => {
        setCurrentExpense(expense)
        console.log('useUpdateExpenseSidebar:selectExpense:', expense)
    }

    const save = async ({costInCents, loggedAt}) => {
        setExpenses(expenses.map(expense => {
            if (expense.id === currentExpense.id) {
                const entryToDelete = expense.entries.find(entry => {
                    const loggedAtDate = toDateTime(decodeLoggedAt(entry.loggedAt))
                    const loggedAtMonth = loggedAtDate.getMonth()
                    const loggedAtYear = loggedAtDate.getFullYear()

                    const newLoggedAtMonth = loggedAt.getMonth()
                    const newLoggedAtYear = loggedAt.getFullYear()
                    console.log('useExpenses:save:entryToDelete:', loggedAtDate, loggedAtMonth, loggedAtYear, newLoggedAtMonth, newLoggedAtYear)

                    return loggedAtMonth === newLoggedAtMonth && loggedAtYear === newLoggedAtYear
                })
                const newEntry = {
                    uuid: uuidv4(),
                    costInCents,
                    loggedAt: new Date(loggedAt)
                }
                const newEntries = [...expense.entries.filter(entry => entry.uuid !== entryToDelete?.uuid), newEntry]
                return {
                    ...expense,
                    entries: newEntries
                }
            }
            return expense
        }))
        setCurrentExpense(null)
    }

    const removeEntry = async ({expenseId, uuid}) => {
        setExpenses(expenses.map(expense => {
            if (expense.id === expenseId) {
                return {
                    ...expense,
                    entries: expense.entries.filter(entry => entry.uuid !== uuid)
                }
            }
            return expense
        }))
    }

    const deleteExpense = async (expenseId) => {
        setCurrentExpense(null)
        setExpenses(expenses.filter(expense => expense.id !== expenseId))
    }

    const addExpense = async ({newExpenseName, newExpenseCost, loggedAt, expenseType}) => {
        setExpenses([...expenses, {
            id: uuidv4(),
            userId: accountId,
            name: newExpenseName,
            expenseType,
            entries: [{
                uuid: uuidv4(),
                costInCents: newExpenseCost*100,
                loggedAt: loggedAt
            }]
        }])
    }

    return { expenses, currentExpense, controls: {selectExpense, save, deleteExpense, addExpense, removeEntry} };
};

export default useDemoExpenses;
