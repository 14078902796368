import {DialogTitle} from "@headlessui/react";
import {Form} from "./ExpensesStepPage";
import {BanknotesIcon} from "@heroicons/react/16/solid";
import {formatMoney} from "../../utils/utils";

export const SummaryStepPage = ({investments, passiveIncome})  => {

    const withdrawalRateRaw = investments && investments.amount * 0.04 / 12
    const passiveIncomeRaw = passiveIncome.amount ? parseInt(passiveIncome.amount) : 0

    console.log(withdrawalRateRaw, passiveIncomeRaw)

    return <div className="mt-3 sm:mt-5">
        {/*<DialogTitle as="h2" className="text-base font-semibold leading-6 text-gray-900">*/}
        {/*    Your Withdrawal Number*/}
        {/*</DialogTitle>*/}

        <div className="space-y-12">

            <div className="border-b border-gray-900/10 pb-12">
                <p className="mt-1 text-sm leading-6 text-gray-600">
                    {/*Doesn't matter what you have in mind: financial independence, F.I.R.E, partial financial independence. Financial independence Kaizen way have you covered.*/}
                </p>


                <div
                    className="cursor-pointer mb-5 relative overflow-hidden rounded-lg bg-indigo-50 px-4 pt-5 shadow sm:px-6 sm:pt-6"
                    onClick={() => {}}
                >
                    <dt>
                        <div className="absolute rounded-md bg-indigo-500 p-3">
                            <BanknotesIcon aria-hidden="true" className="h-6 w-6 text-white"/>
                        </div>
                        <p className="ml-16 truncate text-sm font-medium text-gray-500">Monthly Withdrawal Number</p>
                    </dt>
                    <dd className="ml-16 flex items-baseline pb-6 sm:pb-4">
                        <p className="text-2xl font-semibold text-gray-900">
                            {
                                formatMoney(withdrawalRateRaw+passiveIncomeRaw)
                            }
                        </p>
                    </dd>
                </div>

                <p className="mt-1 text-sm leading-6 text-gray-600">
                    This is how much money you can withdraw from your investments every month without running out of money based on 4% rule.
                </p>

                <p className="mt-10 text-sm leading-6 text-gray-600">
                    *Your monthly withdrawal number is calculated based on your invesments of {formatMoney(investments.amount)} multiplied by 4% annual return and divided by 12 month plus your monthly passive income.
                    <br/>
                    4% is a common rule used for calculation of safe withdrawal rate. If you want to use different rate, you will be able to change it in future.
                </p>

            </div>
        </div>
    </div>
}
