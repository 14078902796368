import { useState, useEffect } from 'react';
import {collection, query, where, getDocs, doc, deleteDoc, updateDoc, arrayUnion, arrayRemove, addDoc} from "firebase/firestore";
import { db } from '../firebaseInit';
import {getLastEntry} from "../utils/utils";

export const usePassiveIncomeEntries = (accountId) => {
    const [passiveIncomeEntries, setPassiveIncomeEntries] = useState([]);
    const [cacheLastInvalidatedAt, setCacheLastInvalidatedAt] = useState(Date.now());

    useEffect(() => {
        const load = async () => {
            try {
                const q = query(collection(db, "passiveIncomeEntries"), where("userId", "==", accountId));

                const querySnapshot = await await getDocs(q);

                const passiveIncomeEntries = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                console.log('usePortfolioNavUpdates passiveIncomeEntries: ', passiveIncomeEntries)

                setPassiveIncomeEntries(passiveIncomeEntries);
            } catch (error) {
                console.error("Error fetching expenses: ", error);
            }
        };

        if (accountId) {
            load();
        }
    }, [accountId, cacheLastInvalidatedAt]);

    const addPassiveIncomeEntry = async ({title, amountInCents}) => {
        try {
            await addDoc(collection(db, "passiveIncomeEntries"), {
                userId: accountId,
                amountInCents: amountInCents,
                loggedAt: new Date(),
                title,
            });
            setCacheLastInvalidatedAt(Date.now())
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    }

    const removePassiveIncomeEntry = async (id) => {
        try {
            const docRef = doc(db, "passiveIncomeEntries", id);
            await deleteDoc(docRef);
            setCacheLastInvalidatedAt(Date.now())
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    }

    const passiveIncomeEntriesTotal = passiveIncomeEntries.reduce((acc, entry) => acc + entry.amountInCents, 0);

    return { passiveIncomeEntries, passiveIncomeEntriesTotal, addPassiveIncomeEntry, removePassiveIncomeEntry };
};

