import {Dialog, DialogPanel, DialogTitle} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import Datepicker from "react-tailwindcss-datepicker";
import {useEffect, useState} from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {formatMoney, toDateTime} from "../utils/utils";
import classNames from "classnames";
import {SubmitButton} from "./Button";

export const InvestmentPlanSidebar = ({userData, isOpen, setIsOpen, addInvestmentPlanEntry, removeInvestmentPlanEntry, portfolioNavStore}) => {
    const [newAmount, setNewAmount] = useState(0)
    const [loggedAt, setLoggedAt] = useState({
        startDate: new Date(),
        endDate: new Date().setMonth(11)
    })
    const [confirmDeleteEntryTimestamp, setConfirmDeleteEntryTimestamp] = useState(null)
    const [isUpdatingAmount, setIsUpdatingAmount] = useState(false)

    if (!userData) return null

    const investmentPlanEntries = userData.investmentPlanEntries || []

    return <Dialog open={isOpen} onClose={setIsOpen} className="relative z-40">
        <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                    <DialogPanel
                        transition
                        className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                    >
                        <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                            <div className="h-0 flex-1 overflow-y-auto">
                                <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                                    <div className="flex items-center justify-between">
                                        <DialogTitle className="text-base font-semibold leading-6 text-white">Log Recent Investment</DialogTitle>
                                        <div className="ml-3 flex h-7 items-center">
                                            <button
                                                type="button"
                                                onClick={() => setIsOpen(false)}
                                                className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                            >
                                                <span className="absolute -inset-2.5" />
                                                <span className="sr-only">Close panel</span>
                                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mt-1">
                                        <p className="text-sm text-indigo-300">
                                            Wanna log investment you recently made?
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-1 flex-col justify-between">
                                    <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                        <div className="space-y-6 pb-5 pt-6">
                                            <div>
                                                <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Invested Amount
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="value"
                                                        name="value"
                                                        type="number"
                                                        value={newAmount}
                                                        onChange={(e) => setNewAmount(e.target.value)}
                                                        rows={4}
                                                        className="ddblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-1 flex-col justify-between">
                                    <div className="divide-y pb-6 divide-gray-200 px-4 sm:px-6">
                                        <div className="space-y-6 pb-5 pt-6">
                                            <div>
                                                <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Invested At
                                                </label>
                                                <div className="mt-2">
                                                    <DatePicker
                                                        className="ddblock w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        selected={loggedAt.startDate}
                                                        onChange={(date) => setLoggedAt({startDate: date})}
                                                    />
                                                    {/*<Datepicker*/}
                                                    {/*    primaryColor={"violet"}*/}
                                                    {/*    showDropdowns={false}*/}
                                                    {/*    numberOfMonths={1}*/}
                                                    {/*    showCalendar={true}*/}
                                                    {/*    asSingle={true}*/}
                                                    {/*    value={loggedAt}*/}
                                                    {/*    onChange={handleValueChange}*/}
                                                    {/*    showShortcuts={true}*/}
                                                    {/*/>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="flex flex-1 flex-col justify-between">
                                    <div className="divide-gray-200 px-4 sm:px-6">
                                        <div className="mt-5 mb-3 text-base font-semibold leading-6 text-gray-900">Investement History</div>
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead>
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                                    Date
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Amount Invested
                                                </th>
                                                <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Actions
                                                </th>

                                            </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200">
                                            {investmentPlanEntries.sort((a,b) => b.loggedAt.seconds - a.loggedAt.seconds).map((entry) => (
                                                <tr key={entry.loggedAt.seconds}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                                        {toDateTime(entry.loggedAt.seconds).toLocaleDateString()}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatMoney(entry.costInCents/100)}</td>
                                                    <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                                                        {confirmDeleteEntryTimestamp === entry.uuid ? (
                                                                <button
                                                                    onClick={async () => {
                                                                        await removeInvestmentPlanEntry({
                                                                            costInCents: entry.costInCents,
                                                                            uuid: entry.uuid,
                                                                            portfolioNavStore
                                                                        })
                                                                    }}
                                                                    type="button"
                                                                    className={classNames(
                                                                        "rounded-full px-2.5 py-1 text-xs font-semibold shadow-sm ring-1 ring-inset",
                                                                        "bg-red-500 text-red-50 ring-red-300 hover:bg-red-500 "
                                                                    )}
                                                                >
                                                                    Confirm
                                                                </button>) :
                                                            <button
                                                                onClick={() => {
                                                                    setConfirmDeleteEntryTimestamp(entry.uuid)
                                                                }}
                                                                type="button"
                                                                className={classNames(
                                                                    "rounded-full px-2.5 py-1 text-xs font-semibold shadow-sm ring-1 ring-inset",
                                                                    " bg-red text-red-900 ring-red-300 hover:bg-red-50 "
                                                                )}
                                                            >
                                                                &nbsp;Delete&nbsp;&nbsp;
                                                            </button>}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                <button
                                    type="button"
                                    onClick={() => setIsOpen(false)}
                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    Cancel
                                </button>
                                <SubmitButton
                                    text="Submit"
                                    loadingText={"Submitting..."}
                                    onSubmit={async () => {
                                        await addInvestmentPlanEntry({
                                            costInCents: newAmount * 100,
                                            loggedAt: loggedAt.startDate,
                                            portfolioNavStore
                                        })
                                    }}
                                    />
                            </div>
                        </form>
                    </DialogPanel>
                </div>
            </div>
        </div>
    </Dialog>
}
