import {Layout} from "../components/Layout";

export const Terms = ({mode}) => {
    return <Layout pageSlug="terms" mode={mode}>
        <div class={"bg-gray-100 text-gray-800 p-4"}>
        <div class="max-w-3xl mx-auto bg-white p-6 rounded-lg shadow-md">
            <h1 class="text-3xl font-bold mb-6">Terms and Conditions</h1>

            <section class="mb-8">
                <h2 class="text-xl font-semibold mb-2">Acceptance of Terms</h2>
                <p class="mb-4">By using Zefire.app you agree to these terms. If you have any questions or concerns, feel free to reach out to us. If you're not comfortable with the terms, it's perfectly okay to choose not to use our services.</p>
                <p class="font-semibold">Changes and Updates:</p>
                <p>We may update these terms from time to time. By continuing to use our services, you accept any changes. We will notify you of significant changes via email or on our web page.</p>
            </section>

            <section class="mb-8">
                <h2 class="text-xl font-semibold mb-2">No Financial Advice Provided</h2>
                <p class="mb-4"><span class="font-semibold">Informational Use Only:</span> The content on Zefire.app is for educational purposes and does not constitute financial, legal, or tax advice. Always consult a professional for specific advice.</p>
                <p class="mb-4"><span class="font-semibold">Results Disclaimer:</span> Any financial results or predictions provided are estimates and should not be considered guarantees of actual outcomes.</p>
                <p><span class="font-semibold">No Warranties:</span> We do not guarantee the accuracy, completeness, or reliability of our content or tools.</p>
            </section>

            <section class="mb-8">
                <h2 class="text-xl font-semibold mb-2">Limitations</h2>
                <p class="mb-4"><span class="font-semibold">User Responsibility:</span> You’re in charge of your own financial decisions, and our content and tools are here to help, but you should use them at your own risk.</p>
                <p class="mb-4"><span class="font-semibold">Indemnification:</span> By using Zefire.app, you agree to protect us from any claims or losses that might come from your use of our services.</p>
                <p class="mb-4"><span class="font-semibold">Financial Loss:</span> We’re not responsible for any damages, like financial losses or data issues, that might happen when using our website or app.</p>
                <p><span class="font-semibold">Third-Party Links:</span> Sometimes we link to other websites, but we’re not responsible for the content or accuracy of what’s on those sites.</p>
            </section>

            <section class="mb-8">
                <h2 class="text-xl font-semibold mb-2">Intellectual Property</h2>
                <p class="mb-4"><span class="font-semibold">Our Content:</span> All content on Zefire.app, including text, images, and software, is owned by us or our licensors and is protected by copyright laws. Please do not use our content without permission.</p>
                <p><span class="font-semibold">Use of Content:</span> Use the content for personal, non-commercial purposes only.</p>
            </section>

            <section class="mb-8">
                <h2 class="text-xl font-semibold mb-2">Subscription and Payment Terms</h2>
                <p class="mb-4"><span class="font-semibold">Payment Processing:</span> Paddle</p>
                <p class="mb-4"><span class="font-semibold">Subscription Terms:</span> Subscriptions are charged upfront and will renew automatically unless you cancel.</p>
                <p class="mb-4"><span class="font-semibold">Cancellation Process:</span> You can cancel your subscription at any time through your account settings.</p>
                <p><span class="font-semibold">Refund Policy:</span> If you’re not happy with your subscription, you can request a refund under the following conditions:</p>
                <ul class="list-disc list-inside pl-4">
                    <li><span class="font-semibold">Eligibility:</span> Refund requests must be made within 14 days of the initial subscription purchase.</li>
                    <li><span class="font-semibold">Case-by-Case Evaluation:</span> Each refund request will be reviewed individually, considering the circumstances and usage of the service.</li>
                    <li><span class="font-semibold">How to Request:</span> To request a refund, please contact us at <a href="mailto:info@zefire.app" class="text-blue-600 hover:underline">info@zefire.app</a> with your subscription details and the reason for your request.</li>
                </ul>
            </section>

            <section class="mb-8">
                <h2 class="text-xl font-semibold mb-2">Application Disclaimer</h2>
                <p class="mb-4"><span class="font-semibold">Creating an Account:</span> When signing up, please provide current and accurate details. You’re responsible for keeping your login information secure.</p>
                <p class="mb-4"><span class="font-semibold">Securing Your Account:</span> Your account’s security is in your hands. If you suspect unauthorized access, notify us immediately.</p>
                <p class="mb-4"><span class="font-semibold">Service Availability:</span> We aim to keep our services running smoothly, but we can’t guarantee uninterrupted access.</p>
                <p><span class="font-semibold">Service Modifications:</span> We may change or stop offering certain features at any time, with or without notice.</p>
                <p><span class="font-semibold">Third-Party Integrations:</span> Zefire.app is not liable for any content or actions of third-party services linked to or used within our platform. Use them at your own discretion and under their terms.</p>
            </section>

            <section class="mb-8">
                <h2 class="text-xl font-semibold mb-2">User Feedback and Comments</h2>
                <p>When you share suggestions with us, they become our property, allowing us to use them freely. This means we won’t owe you anything in return, but your contributions help us improve and grow.</p>
            </section>

            <section class="mb-8">
                <h2 class="text-xl font-semibold mb-2">Governing Law</h2>
                <p>These terms are governed by the laws of the Czech Republic, and any disputes will be handled in the Czech Republic's courts.</p>
            </section>

            <section>
                <h2 class="text-xl font-semibold mb-2">Contact Information</h2>
                <p>Mind Technologies s.r.o</p>
                <p>Rixdorfska 2924/6</p>
                <p>Praha 130 00</p>
                <p>Email: <a href="mailto:info@zefire.app" class="text-blue-600 hover:underline">info@zefire.app</a></p>
            </section>
        </div>
        </div>
    </Layout>
}
