import {Layout} from "../components/Layout";
import {PhotoIcon, UserCircleIcon} from "@heroicons/react/16/solid";
import {formatMoney} from "../utils/utils";
import {useAuth} from "../providers/authprovider";
import useUserDataStore from "../hooks/useUserDataStore";

export const DemoSettings = () => {
    return (
        <Layout pageSlug="settings" mode={"demo"}>
            <div className="px-10 py-10">
                <div className="space-y-10 divide-y divide-gray-900/10">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
                        <div className="px-4 sm:px-0">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Settings</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                Manage your account settings.
                            </p>


                            <p className="mt-5 text-sm text-gray-900 bg-indigo-50 rounded-sm px-3 py-3 font-medium mb-10">
                                <div className={"text-lg font-bold"}>
                                    💡 Settings are disabled in demo mode
                                </div>
                                <div className="">
                                    <div className={"pl-5 pt-2"}>Your data is <b>temporarily stored</b> in your browser, and some features are limited.
                                        <br/>
                                        <br/>

                                        To store your data reliably and unlock all features, including <b>goal tracking</b>, settings and be able to use the app across devices, <b>create a free account</b>.
                                        <br/>
                                        <br/>
                                    </div>

                                    <button
                                        onClick={() => window.location.href = "/signup"}
                                        type="button"
                                        className="w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Sign up for free
                                    </button>
                                    {/*Sign up for a trial to access all features — no credit card required.*/}
                                    {/*<br/>*/}
                                    {/*The trial lasts for 7 days and will be automatically extended until the project exits beta.*/}

                                    {/*<br/>*/}

                                </div>
                            </p>
                        </div>

                        <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                            <div className="px-4 py-6 sm:p-8">


                                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-4">
                                        <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                                            Your withdrawal rate (annually, in %)
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm"></span>
                                                <input
                                                    id="website"
                                                    name="website"
                                                    disabled={true}
                                                    type="number"
                                                    placeholder="4"
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                            {/*<p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about yourself.</p>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="px-4 py-6 sm:p-8">
                                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-4">
                                        <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                                            Currency
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm"></span>
                                                <input
                                                    id="website"
                                                    name="website"
                                                    disabled={true}
                                                    type="text"
                                                    placeholder="Not set"
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                            {/*<p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about yourself.</p>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                                <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    disabled={true}
                                    className="rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
